import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { branches, Restuarant } from '../restaurants/RestaurantModel';

@Injectable({
  providedIn: 'root'
})
export class BranchesService {

  constructor(private http: HttpClient) { }

  getRestaurantobserv(id) :Observable<any>{
    return this.http.get<Array<Restuarant>>(`${environment.endpoint}/Restaurant/getRestaurantBranches/${id}`)
    .pipe(
      shareReplay()
    );
  }

  getRestaurant(id) {
    return this.http.get<any>(`${environment.endpoint}/Restaurant/getRestaurantBranches/${id}`);
  }

  // used for branch
  getBrachReport(id) {
    return this.http.get<Array<Restuarant>>(`${environment.endpoint}/orders/getorderReport/${id}`);
  }

  getCancelationOrderReportForVendor(id:number){
    return this.http.get(`${environment.endpoint}/orders/getCancelationOrderReportForVendor/${id}`)
  }


  getorderReportForBranch(id) {
    return this.http.get<Array<Restuarant>>(`${environment.endpoint}/orders/getorderReportForBranch/${id}`);
  }

  // used for vendor
  getResturantbills(id) {
    return this.http.get<Array<Restuarant>>(`${environment.endpoint}/bills/indexForVendor/${id}`);
  }
  getResturantReport(id) {
    return this.http.get<Array<Restuarant>>(`${environment.endpoint}/orders/getFinanceReportForVendor/${id}`);
  }
  getorderReportForResturant(id) {
    return this.http.get<Array<Restuarant>>(`${environment.endpoint}/orders/getorderReportForVenor/${id}`);
  }



  delete(data: any) {
    return this.http.get(`${environment.endpoint}/branches/delete/${data}`).toPromise();
  }
  add(data) {
    return this.http.post<branches>(`${environment.endpoint}/Restaurant/addBranches`,data).toPromise();
  }
  edit(data) {
    return this.http.post<any>(`${environment.endpoint}/branches/edit/${data.id}`,data).toPromise();
  }

  filtrOrdersForBills(id: any, filter: any) {
    return this.http.post(`${environment.endpoint}/orders/FiltrOrdersForBills/${id}`, filter)
  }


  getReport(search?){
    return this.http.post(`${environment.endpoint}/branches/getBranchReporForAdmin`,search).toPromise()
  }

}
