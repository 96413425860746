import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { OrdersService } from "src/core/services/orders/orders.service";

@Component({
  selector: "app-account-orders-details",
  templateUrl: "./account-orders-details.component.html",
  styleUrls: ["./account-orders-details.component.scss"],
})
export class AccountOrdersDetailsComponent implements OnInit {
  id: any;
  store: any[] = [];
  status: any[] = [];
  data: any[] = [];
  selectedValue: any = null;
  heads = [
    "#",
    "الفرع",
    "حالة الطلب",
    "النقدية",
    "الفيزا",
    "الاجمالي",
    "العمولة",
    "الخصم",
    "الضريبة",
    "المطلوب",
  ];

  loading: boolean;
  constructor(private service: OrdersService, private router: ActivatedRoute) {
    this.id = this.router.snapshot.params.id;
  }

  ngOnInit() {
    this.get();
    this.orderStatus();
  }

  async get() {
    const data: any = await this.service.getAccountDetails(this.id).toPromise();
    this.store = data.weekorders;
    this.data = data.weekorders;
  }

  async orderStatus() {
    const data: any = await this.service.orderStatus().toPromise();
    this.status = data;
  }

  async filter(filter, val, type = "") {
    if (val) {
      const data = this.store;
      this.data = data.filter((d) => d.order_status_id == val);
    } else this.data = this.store;
  }
}
