import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrdersService {
  constructor(private http: HttpClient) {}
  AddTestOrder(filterBy) {
    return this.http.post(`${environment.endpoint}/orders/add`, filterBy);
  }

  filterOrders(filterBy) {
    return this.http.post(
      `${environment.endpoint}/orderdetails/sellallitems/5.json`,
      filterBy
    );
  }

  getCompanyShipOrders() {
    const formData = new FormData();
    const order_status: any = 0;
    formData.append("Filter[order_status]", "1");
    return this.http
      .post(`${environment.endpoint}/orders.json?page=1`, formData)
      .toPromise();
  }

  getOrdersByFilter(page) {
    return this.http.get(
      `${environment.endpoint}/orders/orders/${localStorage.getItem(
        "BranchId"
      )}`
    );
  }

  orderStatus() {
    return this.http.get(`${environment.endpoint}/orders/orderStatus/index`);
  }

  getOrdersByFilter2(filter, page) {
    return this.http
      .post(`${environment.endpoint}/orders.json?page=${page}`, filter)
      .toPromise();
  }

  changeOrderStatus(id, order_status) {
    return this.http
      .post(`${environment.endpoint}/orders/edit/${id}`, order_status)
      .toPromise();
  }

  getDriver() {
    return this.http
      .get(`${environment.endpoint}/users/GetDrivers.json`)
      .toPromise();
  }

  getDelivery(data: any) {
    // return this.http.get(`${environment.endpoint}/delivers/indexForBranch`).toPromise()
    return this.http
      .post(`${environment.endpoint}/delivers/indexForBranch`, data)
      .toPromise();
  }

  getOperations(params: any) {
    return this.http.get(`${environment.endpoint}/orders/ordersOperation`, {
      params,
    });
  }

  getAccounts() {
    return this.http.get(
      `${environment.endpoint}/orders/getorderReportForBranch`
    );
  }

  getAccountDetails(id) {
    return this.http.get(
      `${environment.endpoint}/orders/getorderReportForBranch/${id}`
    );
  }

  getAccountDetailsFilter(id, filter) {
    return this.http.post(
      `${environment.endpoint}/orders/getorderReportForBranch/${id}`,
      filter
    );
  }

  CheckoutForBranch(filterBy) {
    return this.http.post(`${environment.endpoint}/Bills/add`, filterBy);
  }

  status() {
    return this.http
      .get(`${environment.endpoint}/orders/orderStatus/index`)
      .toPromise();
  }
}
