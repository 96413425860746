import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { options } from 'src/app/options';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  getUsers(data = {},params={}) {
    return this.http.post(`${environment.endpoint}/Users/index`,data,
    {params}
    ).toPromise();
  }

  getUsersPagination(params) {
    return this.http.get(`${environment.endpoint}/Users/index`,
    {params}
    ).toPromise();
  }

  getuserSalesDropdown(filter) {
    return this.http.post(`${environment.endpoint}/DropDowns/usersFilter`,filter).toPromise()
  }

  indexForDepartment() {
    return this.http.get(`${environment.endpoint}/Users/indexForDepartment`).toPromise();
  }

  getUsersBygroup(data) {
    return this.http.post(`${environment.endpoint}/Users/index`,data).toPromise();
  }
  getSalesUsers() {
    return this.http.get(`${environment.endpoint}/Users/index`).toPromise();
  }

  addUser(user) {
    return this.http.post(`${environment.endpoint}/Users/add`, user).toPromise();
  }

  editUser(user, id) {
    return this.http.post(`${environment.endpoint}/Users/edituser/${id}`, user).toPromise();
  }

  view(num) {
    return this.http.get(`${environment.endpoint}/users/view/${num}`).toPromise();
  }

  deleteUser(id) {
    return this.http.get(`${environment.endpoint}/Users/delete/${id}`).toPromise();
  }

  getUserGroups() {
    return this.http.get(`${environment.endpoint}/UserGroup/index`).toPromise();
  }

  addUserGroups(user) {
    return this.http.post(`${environment.endpoint}/UserGroup/add`, user).toPromise();
  }

  editUserGroups(user, id) {
    return this.http.post(`${environment.endpoint}/UserGroup/edit/${id}`, user).toPromise();
  }

  deleteUserGroups(id) {
    return this.http.get(`${environment.endpoint}/UserGroup/delete/${id}`).toPromise();
  }

  getReport(data:any){
    return this.http.post(`${environment.endpoint}/users/getClientsReporForAdmin`,data).toPromise()
  }

  userSearch(value){
    return this.http.post<any[]>(`${environment.endpoint}/users/UserSearch`,value).pipe(
      map(d=>d.length)
    ).toPromise()
  }

  setToken(data){
    return this.http.post(`${environment.endpoint}/users/registerToken`,data).toPromise()
  }

  changePassword(data){
    return this.http.post<{status:boolean}>(`${environment.endpoint}/changePassword`,data).toPromise()
  }

}
