import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddComponent } from "./add/add.component";
import { EditComponent } from "./edit/edit.component";
import { IndexComponent } from "./index/index.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "add",
        component: AddComponent,
      },
      {
        path: "index",
        component: IndexComponent,
      },
      {
        path: "edit",
        component: EditComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AddIndexEditDeleteRoutingModule {}
