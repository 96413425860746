import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { BranchesService } from "src/core/services/branches/branches.service";
import { GenericService } from "src/core/services/generic/generic.service";
import { OrdersAdminService } from "src/core/services/orders-admin/orders-admin.service";
import { OrdersHelper } from "../../../classes/orders-helper";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";

@Component({
  selector: "app-order-admin-index",
  templateUrl: "./order-admin-index.component.html",
  styleUrls: ["./order-admin-index.component.css"],
})
export class OrderAdminIndexComponent implements OnInit {
  filterData: any = {};
  @Input("BranchId") BranchId: number;
  filterSub: Subscription;
  data: any = { total: 0, results: [] };
  temp: any[] = [];
  page: number = 1;
  limit: number = 5;

  heads = [
    "#",
    "العميل",
    "العنوان",
    "المنطقة",
    "الفرع",
    "الحالة",
    "وسيلة الدفع",
    "خصم المحفظة",
    "خصم العروض",
    "خصم كود الخصم",
    "الضريبة",
    "الاجمالي",
    "العنوان",
    "الطلب",
  ];
  loading: boolean = false;

  total: number = 0;
  cancel: number = 0;
  profit: number = 0;
  percentage: number = 0;

  billingData: any = {};
  isBillingVisible: boolean = false;
  index: number;

  OrderId;
  queryParams;

  details: any = [];
  isVisible: boolean = false;
  drivers: any = [];

  popupHeader: any[] = ["#", "الطبق", "الكمية", "السعر", "التاريخ", "الخيارات"];

  RestaurantId: any = localStorage.getItem("RestaurantId");

  constructor(
    private service: OrdersAdminService,
    private branche: BranchesService,
    private helper: OrdersHelper,
    private generic: GenericService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
    this.get();

    if (Object.keys(this.queryParams).length) {
      this.filter(this.queryParams);
    }
  }

  async get() {
    if (this.RestaurantId > 0) {
      const data: any = await this.service.filter(
        {
          date_from: moment(
            new Date(new Date().getFullYear(), new Date().getMonth(), 1)
          ).format("YYYY-MM-DD"),
          date_to: moment(new Date()).format("YYYY-MM-DD"),
          filter: { "branches.restaurantsId": this.RestaurantId },
        },
        { page: this.page, limit: this.limit }
      );
      this.data = data.Monthorders;
      this.temp = data.Monthorders;
      console.log(this.data);
      this.percentage = parseFloat(data.Confirgrations[0].value);
    } else if (this.BranchId > 0) {
      const data: any = await this.branche.getorderReportForBranch(
        this.BranchId
      );
      this.data = data.Monthorders;
      this.temp = data.Monthorders;
      this.percentage = parseFloat(data.Confirgrations[0].value);
      console.log(data);
    } else {
      const data: any = await this.service.get({
        page: this.page,
        limit: this.limit,
      });
      if (!this.queryParams.payment) {
        this.data = data.Monthorders;
      }
      this.temp = data.Monthorders;
      this.percentage = parseFloat(data.Confirgrations[0].value);
      console.log(data);
    }

    this.calc();
  }

  async filter(form) {
    if (form == "reset") return this.reset();

    if (this.RestaurantId > 0) {
      form.restaurant = this.RestaurantId;
    }

    let props: any[] = [
      Boolean(form.id) && { "orders.id": parseInt(form.id) },
      Boolean(form.restaurant) && { "branches.restaurantsId": form.restaurant },
      Boolean(form.branch) && { "branches.id": form.branch },
      Boolean(form.area) && { "branches:areas.id": form.area },
      Boolean(form.payment) && { "paymenttype.id": form.payment },
      Boolean(form.status) && { "orders.order_status_id": form.status },
    ];

    props = props.filter((val) => Boolean(val));
    console.log("props ", props);
    let obj = {};
    props.forEach((val) => (obj = { ...obj, ...val }));
    const search = {
      date_from: form.date_from,
      date_to: form.date_to,
      filter: { ...obj },
    };
    if (!search.date_from || !search.date_to) {
      delete search.date_from;
      delete search.date_to;
    }
    this.filterData = search;
    const data: any = await this.service.filter(search, {
      page: this.page,
      limit: this.limit,
    });

    this.data = data.Monthorders;
    this.calc();
  }

  calc() {
    this.total = this.data.results
      .filter((d) => d.order_status_id != 4)
      .map((d) => d.total)
      .reduce((prev, next) => prev + next, 0);
    this.cancel = this.data.results
      .filter((d) => d.order_status_id == 4)
      .map((d) => d.total)
      .reduce((prev, next) => prev + next, 0);
  }

  reset() {
    this.data.results = this.temp;
    this.calc();
  }

  showAdress(adress, event) {
    event.stopPropagation();
    this.billingData = adress;
    this.isBillingVisible = true;
  }

  getOrderDetails(details: any[], adress, index?) {
    details.length == 0
      ? (this.details = [])
      : (this.details = this.helper.createDetails(details));
    this.billingData = adress;
    this.OrderId = index != null ? this.data.results[index].id : this.OrderId;
    this.index = index != null ? index : 0;
    this.isVisible = true;
  }

  successOperation() {
    this.isVisible = false;
  }

  onPageChange(page) {
    this.page = page;
    if (Object.keys(this.filterData).length > 0) this.filter(this.filterData);
    else this.get();
  }

  onSizeChange(limit) {
    this.limit = limit;
    if (Object.keys(this.filterData).length > 0) this.filter(this.filterData);
    else this.get();
  }
}
