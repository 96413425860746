import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

    data
      constructor(
        // private service: MarketingManagementService
        ) { }
    
      async ngOnInit() {
    
        // await this.service.GetMarketingManagement().subscribe(res =>{
        //    console.log(res)
        //    this.data = res
        //  })
       }

}

