import { AreasService } from "src/core/services/areas/areas.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GenericService } from "src/core/services/generic/generic.service";
import { NotificationsService } from "src/core/services/notifications/notifications.service";
import * as $ from "jquery";
import { environment } from "src/environments/environment";
import { UsersService } from "src/core/services/users/users.service";

@Component({
  selector: "app-notifications-form",
  templateUrl: "./notifications-form.component.html",
  styleUrls: ["./notifications-form.component.css"],
})
export class NotificationsFormComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isAdd: boolean = false;
  imageName = "اختر الصورة";
  image: any = "";
  obj: any = null;
  id: any;
  areas: any[] = [];
  users: any[] = [];
  user_id: any;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private service: NotificationsService,
    private generic: GenericService,
    private areasService: AreasService,
    private userService: UsersService,
    private navigate: Router
  ) {}

  ngOnInit() {
    this.createForm();
    this.getdataForDropdown();
    this.obj = localStorage.getItem("item");

    if (this.obj) {
      const obj = JSON.parse(this.obj);
      this.id = obj.id;
      this.form.reset(obj);
    }

    let user = localStorage.getItem("user_id");

    if (user) {
      this.user_id = Number(localStorage.getItem("user_id"));
    } else {
      this.user_id = null;
    }
  }

  createForm() {
    this.form = this.fb.group({
      title: [null, Validators.required],
      text: [null, Validators.required],
      messageType: [null, Validators.required],
      zone: [null],
      userId: [null],
    });
  }

  async getdataForDropdown() {
    let data = await Promise.all([
      this.areasService.get(),
      this.userService.getuserSalesDropdown({}),
    ]);
    this.areas = <any>data[0];
    this.users = <any>data[1];
  }

  getImageName(files: FileList) {
    let fileName = $("#photo")
      .val()
      .toString()
      .replace(/C:\\fakepath\\/i, "");
    this.imageName = fileName;
    this.image = files.item(0);
    console.log(this.image);
  }

  async add() {
    if (this.form.valid && (this.form.get("zone") || this.form.get("userId"))) {
      const values = this.form.getRawValue();
      let userId = values.userId;
      delete values.zone;
      delete values.userId;
      delete values.messageType;
      const formData: any = new FormData();
      formData.append("data", JSON.stringify(values));

      if (this.image) formData.append("img", this.image);

      this.isAdd = true;

      this.service
        .add(formData)
        .then((data: any) => {
          return this.service.sendToDevice(values.title, values.text, userId);
        })
        .then(
          (d) => {
            this.isAdd = false;
            this.generic.showNotification(
              "success",
              "اضافة",
              "تمت العملية بنجاح"
            );
            this.form.reset();
            this.navigate.navigateByUrl("/notifications/all");
          },
          (e) => {
            this.isAdd = false;
            this.generic.showNotification(
              "error",
              "اضافة",
              "حدث خطأ بالرجاء المحاولة في وقت لاحق"
            );
          }
        );
    }
  }

  async edit() {
    if (this.form.valid) {
      const values = this.form.getRawValue();

      console.log("data : ", values);
      console.log("user : ", this.id);

      // delete values.zone;
      // delete values.userId;
      // delete values.messageType;

      const formData: any = new FormData();

      formData.append("data", JSON.stringify(values));

      if (this.image) formData.append("img", this.image);

      this.isAdd = true;

      const data = await this.service.edit(formData, this.id);

      if (data == 1) {
        this.isAdd = false;
        console.log(data);
        this.generic.showNotification("success", "تعديل", "تمت العملية بنجاح");
      } else {
        this.isAdd = false;
        this.generic.showNotification(
          "error",
          "تعديل",
          "حدث خطأ بالرجاء المحاولة في وقت لاحق"
        );
      }

      // .then(
      //   (data : any) => {
      //     this.isAdd = false;
      //     console.log(data);
      //     this.generic.showNotification('success', 'تعديل', 'تمت العملية بنجاح');
      //   },
      //   () => {
      //     this.isAdd = false;
      //     this.generic.showNotification('error', 'تعديل', 'حدث خطأ بالرجاء المحاولة في وقت لاحق');
      //   }
      // )
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem("item");
  }
}
