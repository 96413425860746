import { AddComponent } from "src/app/main/branches/add/add.component";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/core/module/shared.module";
import { FinanceIndexComponent } from "./finance-report-index/financeIndexComponent.component";
import { FinanceReportRoutes } from "./finance-report.routes";

@NgModule({
  declarations: [FinanceIndexComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(FinanceReportRoutes),
  ],
  exports: [],
  providers: [],
  entryComponents: [AddComponent],
})
export class FinanceReportModule {}
