import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AreasCreate } from 'src/core/services/cities/citiesModel';

@Injectable({
  providedIn: "root",
})
export class AdsSpacesNameService {

  constructor(private http: HttpClient) {}

  GetAdsSpacesName() {

    return this.http.get<Array<AreasCreate>>(`${environment.endpoint}/AdsSpacesName/index`).toPromise();

  }
  add(obj) {

    return this.http.post<any>(`${environment.endpoint}/AdsSpacesName/add`,obj).toPromise();

  }
  delete(id) {
    return this.http.get(`${environment.endpoint}/AdsSpacesName/delete/${id}`).toPromise();
  }

  edit(item , obj) {
    return this.http.post(`${environment.endpoint}/AdsSpacesName/edit/${item}`,  obj).toPromise();
  }
}
