import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BillsService {

  constructor(private http: HttpClient) { }

  get(id) {
    return this.http.get(`${environment.endpoint}/bills/index/${id}`);
  }

  getForVendor(id) {
    return this.http.get(`${environment.endpoint}/bills/indexForVendor/${id}`);
  }

  filtrOrdersForBills(id: any, filter: any) {
    return this.http.post(`${environment.endpoint}/orders/FiltrOrdersForBills/${id}`, filter)
  }

  addBill(bill) {
    return this.http.post<any>(`${environment.endpoint}/bills/add`, bill)
  }

  editBill(id: number, bill: any) {
    return this.http.post(`${environment.endpoint}/bills/edit/${id}`,bill).toPromise()
  }

}
