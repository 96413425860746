import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/core/module/shared.module";
import { MarketingManagementRoutingModule } from "./marketing-management-routing.module";
import { AddComponent } from "src/app/main/MarketingManagement/add/add.component";
import { IndexComponent } from "src/app/main/MarketingManagement/index/index.component";
import { AutoCompleteModule } from "primeng/autocomplete";

@NgModule({
  declarations: [AddComponent, IndexComponent],
  imports: [
    CommonModule,
    MarketingManagementRoutingModule,
    SharedModule,
    AutoCompleteModule,
  ],
})
export class MarketingManagementModule {}
