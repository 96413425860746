import { Component, OnInit } from "@angular/core";
import { UsersService } from "src/core/services/users/users.service";

@Component({
  selector: "app-user-report",
  templateUrl: "./user-report.component.html",
  styleUrls: ["./user-report.component.scss"],
})
export class UserReportComponent implements OnInit {
  counts: any = {
    weekclient: {},
    monthclient: {},
  };
  charts: any = {
    monthordersForchart: [],
    weekordersForchart: [],
  };
  tables: any = {
    mostpaymentClients: [],
    mostOrderedClients: [],
  };
  paymentClientsHead: string[] = ["إسم المستخدم", "رقم الهاتف", "المبلغ"];
  orderedClientsHead: string[] = ["إسم المستخدم", "رقم الهاتف", "عدد الطلبات"];
  constructor(private service: UsersService) {}

  ngOnInit() {
    this.getReport({});
  }

  async getReport(search: any) {
    const data = await this.service.getReport(search);
    this.charts.monthordersForchart = data["monthordersForchart"];
    this.charts.weekordersForchart = data["weekordersForchart"];
    this.tables.mostpaymentClients = data["MostpaymentClients"];
    this.tables.mostOrderedClients = data["MostOrderedClients"];
    this.counts.weekclient = { ...data["weekclient"][0] };
    this.counts.monthclient = { ...data["Monthclient"][0] };
  }
}
