import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appPermessions]'
})
export class PermessionsDirective {
  @Input() set appPermessions(permessions: number[]) {
    const userGroup = parseInt(localStorage.getItem('user_group_id'));
    const isAllowed = permessions.includes(userGroup);
    if (isAllowed) this.viewContainer.createEmbeddedView(this.templateRef)
    else this.viewContainer.clear();
  }

  constructor(
    private templateRef: TemplateRef<any>, 
    private viewContainer: ViewContainerRef) { }

}
