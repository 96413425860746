import { GenericService } from "src/core/services/generic/generic.service";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private navigate: Router, private generic: GenericService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          const applicationError = error.headers.get("Application-Error");
          if (applicationError) {
            console.log("applicationError");
            return throwError(applicationError);
          }
          switch (error.status) {
            case 401:
            case 403:
              localStorage.removeItem("token");
              this.generic.showNotification(
                "error",
                "تسجيل الدخول",
                "تحتاج لتسجيل الدخول"
              );
              this.navigate.navigateByUrl("/authentication/login");
              break;
            case 500:
              this.generic.showNotification(
                "error",
                "server error",
                "internal server error!"
              );
              break;
            default:
              console.log("back error : ", error.message);
              break;
          }
        }
        return throwError(error.message);
      })
    );
  }
}
