import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/core/module/shared.module";

import { AdsSpacesNameRoutingModule } from "./ads-spaces-name-routing.module";
import { AddComponent } from "./add/add.component";
import { IndexComponent } from "./index/index.component";

import { NzInputModule } from "ng-zorro-antd/input";
import { NzButtonModule } from "ng-zorro-antd/button";

@NgModule({
  declarations: [AddComponent, IndexComponent],
  imports: [
    CommonModule,
    AdsSpacesNameRoutingModule,
    SharedModule,
    NzInputModule,
    NzButtonModule,
  ],
})
export class AdsSpacesNameModule {}
