import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserSharedDataService {
  user_filterData:BehaviorSubject<any>
  constructor() {
    this.user_filterData = new BehaviorSubject<any>({})
  }
}
