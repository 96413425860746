import { GenericService } from "src/core/services/generic/generic.service";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { OrdersService } from "src/core/services/orders/orders.service";
import { Socket } from "ngx-socket-io";
import { ControlPosition } from "@agm/core/services/google-maps-types";

@Component({
  selector: "app-popup-details",
  templateUrl: "./popup-details.component.html",
  styleUrls: ["./popup-details.component.css"],
})
export class PopupDetailsComponent implements OnInit, OnChanges {
  @Input("data") data: any[] = [];
  @Input("BillingData") BillingData: any = {};
  @Input("OrderId") OrderId: any = {};
  @Input("OrderObject") OrderObject: any;
  @Input("branches") branches: any = {};

  obj_selected: any;

  @Input("title") title: string;
  @Input("header") header: any[] = [];
  @Input("isVisible") isVisible: boolean = false;
  @Input("order_status_id") order_status_id: any;
  @Input("delivery_id") delivery_id: number;

  @Input("apar_number") apar_number: number;
  @Input("apar_floor") apar_floor: number;

  // data to be sent to parent
  @Output("closeDV") closeDV: EventEmitter<any> = new EventEmitter();
  @Output("update") update: EventEmitter<any> = new EventEmitter();
  @ViewChild("map3", { static: true }) map3;
  lat = 0;
  lng = 0;
  zoom = 15;
  isSendDelivery: boolean;
  deliveryID: any;
  delivery: any[];
  deliveryCoordinate;
  roomid;
  constructor(
    public service: OrdersService,
    private generic: GenericService,
    private socket: Socket
  ) {}

  ngOnInit() {
    this.getDelivery();

    console.log(this.BillingData);

    console.log(this.OrderObject);

    this.socket.on("RetriveDriverCoordinate", (data) => {
      console.log(data);
      this.deliveryCoordinate = data;
    });

    this.socket.on("OrderCanceled", (data) => {
      if (data.delivery_information == 1) {
        this.OrderObject.order_status_id = 3;
        this.generic.showNotification(
          "success",
          "نجاح ",
          "قام المندوب بقبول الطلب  "
        );
        this.handleCancel();
      } else {
        this.generic.showNotification("error", "الفاء", "قام المندوب بالالغاء");
      }

      console.log(data);
    });
  }

  handleCancel() {
    console.log(this.roomid);
    this.socket.emit("LeaveDeliveryRoom", this.roomid);
    this.isVisible = false;
    this.isSendDelivery = false;
    this.closeDV.emit(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const prop in changes) {
      if (changes.hasOwnProperty(prop)) {
        switch (prop) {
          case "data":
            this.data = changes.data.currentValue;
            console.log(this.data);
            break;
          case "title":
            this.title = changes.title.currentValue;
            break;
          case "isVisible":
            this.isVisible = changes.isVisible.currentValue;
            break;
          case "order_status_id":
            this.order_status_id = changes.order_status_id.currentValue;
            console.log(this.order_status_id);

          case "OrderObject":
            this.OrderObject = changes.OrderObject.currentValue;
            console.log(changes.OrderObject.currentValue);

            if (this.OrderObject) {
              if (this.OrderObject.delivery_id != 0) {
                console.log(changes.OrderObject.currentValue.drivers, "lol");
                this.socket.emit(
                  "CreateDeliveryRoom",
                  changes.OrderObject.currentValue.drivers.room_id
                );
              } else {
                this.isSendDelivery = true;
              }
            }

            break;
        }
      }
    }
  }

  async OrderCompleteInKitchen() {
    var dataa = await this.service.changeOrderStatus(this.OrderId, {
      order_status_id: 2,
    });
    this.isSendDelivery = true;
    this.isVisible = false;
    this.closeDV.emit(false);
  }
  async AcceptOrder() {
    console.log(this.OrderId);
    var dataa = await this.service.changeOrderStatus(this.OrderId, {
      order_status_id: 1,
    });
    console.log(dataa);
    if (dataa == 1) {
      this.isVisible = false;
      this.closeDV.emit(false);

      this.update.emit(true);
      this.generic.showNotification("success", "طلب جديد", "تمت العملية بنجاح");
    }
  }

  async CancelOrder() {
    var dataa = await this.service.changeOrderStatus(this.OrderId, {
      order_status_id: 5,
    });
    if (dataa == 1) {
      this.isVisible = false;
      this.closeDV.emit(false);
      this.generic.showNotification(
        "success",
        "طلب جديد",
        "تم رفض الطلب بنجاح"
      );
    }
  }

  async sendOrderToDelivery() {
    console.log("delievery id : ", this.delivery);
    console.log("room id : ", this.delivery[this.deliveryID].room_id);
    console.log("order obj : ", this.OrderObject);
    console.log("sended obj : ", {
      roomId: this.delivery[this.deliveryID].room_id,
      order: this.OrderObject,
    });

    this.socket.emit(
      "CreateDeliveryRoom",
      this.delivery[this.deliveryID].room_id
      // { roomId : this.delivery[this.deliveryID].room_id , order : this.OrderObject }
    );

    console.log(this.deliveryID);
    console.log(this.OrderObject);
    this.roomid = this.delivery[this.deliveryID].room_id;

    var data = [
      this.delivery[this.deliveryID].room_id,
      JSON.stringify(this.OrderObject),
    ];

    this.socket.emit("CreateDeliveryOrder", {
      roomId: this.delivery[this.deliveryID].room_id,
      order: this.OrderObject,
    });
    //this.isVisible = false;
    //  this.closeDV.emit(false);
  }

  async getDelivery() {
    console.log("branch id : ", this.OrderObject);
    if (this.OrderObject) {
      const data: any = await this.service.getDelivery({
        branch_id: this.OrderObject.branches.id,
      });
      console.log(data);
      this.delivery = data;
    }
  }
}
