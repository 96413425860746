import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      (localStorage.getItem("token") != null &&
        request.url.startsWith(environment.endpoint)) ||
      request.url.startsWith(environment.endpoint1)
    ) {
      request = request.clone({
        headers: request.headers.set(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        ),
      });
    }

    return next.handle(request);
  }
}
