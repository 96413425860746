import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserGroup } from "src/core/enums/user.group.enum";
import { BindingService } from "src/core/services/binding/binding.service";
import { MenuCategories } from "src/core/services/cities/citiesModel";
import { GenericService } from "src/core/services/generic/generic.service";
import { MinMenuService } from "src/core/services/min-menu/min-menu.service";
import { NzModalService } from "ng-zorro-antd";
import * as XLSX from "xlsx";
import * as lang from "./../../../../settings/lang";

@Component({
  selector: "app-add-category-modal",
  templateUrl: "./add-category-modal.component.html",
  styleUrls: ["./add-category-modal.component.css"],
})
export class AddCategoryModalComponent implements OnInit {
  @Input() Show = true;
  @Input() CateData = new MenuCategories();
  @Output("closeDV") closeDV: EventEmitter<any> = new EventEmitter();

  RestId = 0;
  userGroup = UserGroup;
  NZmodalService: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: MinMenuService,
    private generic: GenericService,
    private binding: BindingService
  ) {}

  ngOnInit() {
    this.RestId = parseInt(this.activatedRoute.snapshot.paramMap.get("id"));
    console.log(this.CateData);
    if (this.CateData == null) {
      this.CateData = new MenuCategories();
    }
  }
  handleCancel() {
    this.Show = false;
    this.binding.changeTitle(false);
  }

  async save() {
    this.CateData.restaurant_id = this.RestId;
    delete this.CateData.hover;
    delete this.CateData.active;

    console.log(this.CateData);

    const data = await this.service.addCategoryMenu(this.CateData);
    if (data.id != null || data == 1) {
      this.Show = false;
      this.closeDV.emit(false);

      this.generic.showNotification(
        "success",
        "تمت العملية",
        "تمت اضافة القسم بنجاح"
      );

      this.binding.changeTitle(true);
    }
  }

  onFileChangee(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const ab: ArrayBuffer = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(ab);

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];

      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.addFromXls(data);
      console.log(data);
    };
    reader.readAsArrayBuffer(target.files[0]);
  }

  async addFromXls(data: any[]) {
    const obj: any = {};

    for (let i = 1; i < data.length; i++) {
      obj.name = data[i][0];
      obj.name_en = data[i][1];
      obj.restaurant_id = this.RestId;

      const add = await this.service.addCategoryMenu(obj);

      if (add) {
        this.generic.showNotification(
          "success",
          lang.ar.addNewTitle,
          lang.ar.addNewMsg
        );
        this.Show = false;

        this.binding.changeTitle(true);
      } else {
        this.generic.showNotification(
          "warning",
          "اضافة",
          "حدث خطأ بالرجاء المحاولة في وقت لاحق"
        );
      }
    }
  }
}
