import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get(`${environment.endpoint}/faq/index`).toPromise();
  }

  delete(id) {
    return this.http.get(`${environment.endpoint}/faq/delete/${id}`).toPromise();
  }

  add(obj) {
    return this.http.post(`${environment.endpoint}/faq/add`, obj).toPromise();
  }

  edit(obj, id) {
    return this.http.post(`${environment.endpoint}/faq/edit/${id}`, obj).toPromise();
  }
 
}
