import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DropdownModule} from 'primeng/dropdown';
import {ChartModule} from 'primeng/chart';
import {PaginatorModule} from 'primeng/paginator';
import {DataViewModule} from 'primeng/dataview';
import {ProgressBarModule} from 'primeng/progressbar';
import {CalendarModule} from 'primeng/calendar';
import {AccordionModule} from 'primeng/accordion';

import {ScrollPanelModule} from 'primeng/scrollpanel';
import {DialogModule} from 'primeng/dialog';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {PasswordModule} from 'primeng/password';

const primengModules= [
  OverlayPanelModule,
  DropdownModule,
  ChartModule,
  PaginatorModule,
  DataViewModule,
  ProgressBarModule,
  CalendarModule,
  ScrollPanelModule,
  DialogModule,
  InputTextModule,
  FileUploadModule,
  TableModule,
  AccordionModule,
  ToastModule,
  ConfirmDialogModule,
  PasswordModule
]
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    primengModules
  ],
  exports:[
    primengModules
  ]
})
export class PrimengModule { }
