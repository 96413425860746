import { SharedModule } from "src/core/module/shared.module";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { InteractionDepRoutes } from "./interaction-management.routes";
import { InterManagApproveComponent } from "./index-inter-dep-approve/index-inter-dep-content-approve.component";

@NgModule({
  declarations: [InterManagApproveComponent],
  imports: [RouterModule.forChild(InteractionDepRoutes), SharedModule],
  exports: [],
  providers: [],
})
export class InteractionManagementModule {}
