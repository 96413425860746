import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AdsSpacesNameService } from "src/core/services/ads-space-name/ads-spaces-name.service";
import { AdsService } from "src/core/services/Ads/Ads.service";
import { GenericService } from "src/core/services/generic/generic.service";

@Component({
  selector: "app-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
})
export class AddComponent implements OnInit {
  form: FormGroup;
  isAdd: boolean = false;
  posistions: any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  ads_space_positions_names: any[] = [];
  obj: any = null;
  id: any;
  Ad: any[] = [];

  constructor(
    private _AdsService: AdsService,
    private _AdsSpacesNameService: AdsSpacesNameService,
    private navigate: Router,
    private generic: GenericService,
    private fb: FormBuilder,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: [null, Validators.required],
    });
  }
  async add() {
    if (this.form.valid) {
      const values = this.form.getRawValue();

      console.log("data : ", values);

      delete values.menu_categories_id;

      const formData: any = new FormData();

      console.log("final to be sent -> data : ", values);

      formData.append("data", JSON.stringify(values));

      this.isAdd = true;

      await this._AdsSpacesNameService.add(values).then(
        () => {
          this.isAdd = false;

          this.generic.showNotification(
            "success",
            "اضافة",
            "تمت العملية بنجاح"
          );
          this.router.navigate(["AdsSpacesName", "index"]);
          // this.form.reset();
        },
        () => {
          this.isAdd = false;
          this.generic.showNotification(
            "error",
            "اضافة",
            "حدث خطأ بالرجاء المحاولة في وقت لاحق"
          );
        }
      );
    }
  }
  async edit() {
    if (this.form.valid) {
      const values = this.form.getRawValue();
      this.isAdd = true;

      await this._AdsService
        .editAd(values, this.id)
        .toPromise()
        .then(
          () => {
            this.isAdd = false;
            this.generic.showNotification(
              "success",
              "تعديل",
              "تمت العملية بنجاح"
            );
            localStorage.removeItem("item");
          },
          () => {
            this.isAdd = false;
            this.generic.showNotification(
              "error",
              "تعديل",
              "حدث خطأ بالرجاء المحاولة في وقت لاحق"
            );
          }
        );
    }
  }
}
