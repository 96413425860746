import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Restuarant } from "src/core/services/restaurants/RestaurantModel";
import { environment } from "src/environments/environment";
import { OrdersService } from "src/core/services/orders/orders.service";

@Component({
  selector: "card-details.component",
  templateUrl: "./card-details.component.html",
  styleUrls: ["./card-details.component.scss"],
})
export class CardDetailsComponent implements OnInit {
  id: any;
  store: any[] = [];
  status: any[] = [];
  data: any[] = [];
  selectedValue: any = null;
  restuarant!: Restuarant;
  CoverSrc: any;
  imageSrc: any = "../../assets/images/user.png";
  baseUrl = environment.photoPath;

  constructor(private service: OrdersService, private router: ActivatedRoute) {
    this.id = this.router.snapshot.params.id;
  }

  ngOnInit() {}
}
