import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddComponent } from "src/app/main/MarketingManagement/add/add.component";
import { IndexComponent } from "src/app/main/MarketingManagement/index/index.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "index",
        component: IndexComponent,
      },
      {
        path: "add",
        component: AddComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MarketingManagementRoutingModule {}
