import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AddIndexEditDeleteService } from 'src/core/services/add-index-edit-delete/add-index-edit-delete.service';
import { GenericService } from 'src/core/services/generic/generic.service';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

 
  validateForm!: FormGroup;
  

 

  submitForm(): void {
    if (this.validateForm.valid) {
      console.log('submit', this.validateForm.value);
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }


  constructor(
    private service: AddIndexEditDeleteService , 
    public fb: FormBuilder,    
    private router: Router ,     
    private generic: GenericService,
    ) {}

  ngOnInit() {
    
    this.validateForm = this.fb.group({
      title: [null, [Validators.required]],
      title_en: [null, [Validators.required]],
      
    });
  }

 async AddRes() {

    console.log(this.validateForm.getRawValue());
    const data = await this.service.addAd(this.validateForm.getRawValue());
    this.generic.showNotification('success', 'اضافة', 'تمت اضافة المطعم بنجاح')
    this.router.navigateByUrl('/AddIndexEditDelete/index')

  }

  }
  
  

  
  


// export interface HomeModel {
//   id?:                       string;
//   title?:   string;
//   positions?:                string;
//   title_en?:             string;
//   created?:                number;
//   modified?:                 number;
// }
