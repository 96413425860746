import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { AreasService } from "src/core/services/areas/areas.service";
import { UserSharedDataService } from "src/core/services/users/user-shared-data.service";

@Component({
  selector: "app-user-filter",
  templateUrl: "./user-filter.component.html",
  styleUrls: ["./user-filter.component.scss"],
})
export class UserFilterComponent implements OnInit {
  form: FormGroup;
  zones: any[] = [];

  constructor(
    private fb: FormBuilder,
    private userShared: UserSharedDataService,
    private areasService: AreasService
  ) {}

  ngOnInit() {
    this.createForm();
    // this.getArea();
  }

  async getArea() {
    let data = await this.areasService.get();
    this.zones = <any>data;
  }

  createForm() {
    this.form = this.fb.group({
      id: [null, []],
      mobile: [null, []],
      zone: [null, []],
    });
  }

  reset() {
    this.form.reset();
    this.userShared.user_filterData.next("reset");
  }
  filter() {
    this.userShared.user_filterData.next(this.form.value);
  }
}
