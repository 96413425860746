import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { NzNotificationService } from "ng-zorro-antd";
import { DepartmentsEnum } from "src/core/enums/departments.enum";
import { environment } from "src/environments/environment";
import { DepartmentsService } from "src/core/services/departments/departments.service";
import { GenericService } from "src/core/services/generic/generic.service";
import { Restuarant } from "src/core/services/restaurants/RestaurantModel";
import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";

@Component({
  selector: "app-content-approve",
  templateUrl: "./index-inter-dep-content-approve.component.html",
  styleUrls: ["./index-inter-dep-content-approve.component.scss"],
})
export class InterManagApproveComponent implements OnInit {
  restaurants: Restuarant[] = [];
  tabs: any[] = [];
  currentTab: number;
  form: FormGroup;
  loading: boolean = false;
  URL = environment.photoPath;
  baseUrl = environment.photoPath;
  departmentEnum = DepartmentsEnum;

  imageSrc: any = "../../assets/images/user.png";
  constructor(
    private service: DepartmentsService,
    private navigate: Router,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private restaurantsService: RestaurantsService,
    private generic: GenericService
  ) {}

  ngOnInit() {
    // top tabs
    this.RestaurantDepartements();

    this.form = this.fb.group({
      name: [null],
      category_id: [null],
      restaurant_general_status_id: [null],
      restaurants_status_id: [null],
      restaurants_types_id: [null],
      sales_id: [null],
      created: [null],
    });
  }

  openLg(id, RestId) {
    this.navigate.navigate(["/branches/all/" + RestId]);
  }

  cancel(): void {}

  edit(i) {
    this.navigate.navigateByUrl("/restaurants/edit", {
      state: this.restaurants[i],
    });
  }

  async confirm(RestId): Promise<void> {
    const data = await this.service.delete({ id: RestId });

    if (data != 1) {
      this.restaurants.forEach((element, index) => {
        if (element.id == RestId) {
          this.restaurants.splice(index, 1);
        }
      });
      this.notification.error("error", data.toString());
    } else {
      this.notification.success("Done", "تم المسح");
    }
  }

  addOffer(f) {}

  async delete(id, index) {
    this.loading = true;
    this.service.delete(id).then((res) => {
      this.restaurants.splice(index, 1);
      this.loading = false;
      this.notification.success("حذف", "تمت العملية بنجاح");
    });
  }

  async filter() {
    let props: any[] = [
      Boolean(this.form.get("name").value) && {
        "restaurants.name": this.form.get("name").value,
      },
      Boolean(this.form.get("category_id").value) && {
        "restaurants.category_id": this.form.get("category_id").value,
      },
      Boolean(this.form.get("restaurant_general_status_id").value) && {
        "restaurants.restaurant_general_status_id": this.form.get(
          "restaurant_general_status_id"
        ).value,
      },
      Boolean(this.form.get("restaurants_status_id").value) && {
        "restaurants.restaurants_status_id": this.form.get(
          "restaurants_status_id"
        ).value,
      },
      Boolean(this.form.get("restaurants_types_id").value) && {
        "restaurants.restaurants_types_id": this.form.get(
          "restaurants_types_id"
        ).value,
      },
      Boolean(this.form.get("sales_id").value) && {
        "restaurants.sales_id": this.form.get("sales_id").value,
      },
      Boolean(this.form.get("created").value) && {
        "restaurants.created": this.generic.formatDate(
          this.form.get("created").value,
          "filter"
        ),
      },
    ];

    props = props.filter((val) => Boolean(val));
    let obj = { restaurant_general_status_id: this.currentTab };
    props.forEach((val) => (obj = { ...obj, ...val }));

    const data: any = await this.restaurantsService.FilterRestaurant(obj);
    this.restaurants = data;
  }

  async RestaurantDepartements() {
    var data = await this.service.get();

    for (let dep of data) {
      if (
        dep.id == this.departmentEnum.sales ||
        dep.id == this.departmentEnum.legal ||
        dep.id == this.departmentEnum.finance
      ) {
        this.tabs.push(dep);
      }
    }
    if (this.tabs.length) {
      this.tabs = this.tabs.reverse();
      this.currentTab = this.tabs[0].id;
      this.onTabChange(this.currentTab);
    }
  }

  async onTabChange(tab) {
    console.log("tab clicked : ", tab);

    this.reset();
    this.currentTab = tab;

    const filter = { departments_id: tab, archived: 0 };
    const data: any = await this.restaurantsService.getRestaurantByContent(
      filter
    );

    console.log("res data : ", data);

    this.restaurants = data;
  }

  reset() {
    this.form.reset();
  }
}
