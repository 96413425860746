import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  constructor(private http: HttpClient) { }


  filterUsers(filter) {
    return this.http.post(`${environment.endpoint}/DropDowns/usersFilter`,filter).toPromise()
  }


  FilterRestaurant(filter) {
    return this.http.post(`${environment.endpoint}/DropDowns/resturantsFilter`,filter).toPromise()
  }


  getAreas() {
    return this.http.get(`${environment.endpoint}/DropDowns/areas`).toPromise()
  }



}
