import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AreasCreate } from "src/core/services/cities/citiesModel";
import { HomeModel } from "src/app/main/addIndexEditDelete/index/index.component";

@Injectable({
  providedIn: "root",
})
export class AddIndexEditDeleteService {
  constructor(private http: HttpClient) {}

  GetAddIndexEditDelete() {
    return this.http
      .get<Array<AreasCreate>>(`${environment.endpoint}/HomeMobileTitles/index`)
      .toPromise();
  }

  GetAd() {
    return this.http
      .get<Array<HomeModel>>(`${environment.endpoint}/HomeMobileTitles/index`)
      .toPromise();
  }

  addAd(ad) {
    return this.http
      .post(`${environment.endpoint}/HomeMobileTitles/add`, ad)
      .toPromise();
  }

  editAd(ad, id) {
    return this.http
      .post(`${environment.endpoint}/HomeMobileTitles/edit/${id}`, ad)
      .toPromise();
  }

  deleteOffer(id) {
    return this.http.get(
      `${environment.endpoint}/HomeMobileTitles/delete/${id}`
    );
  }
}
