import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  CommonModule,
  LocationStrategy,
  HashLocationStrategy,
  registerLocaleData,
} from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Routes, RouterModule } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from "@agm/core";
import { SlimLoadingBarModule } from "ng2-slim-loading-bar";
import { EffectsModule } from "@ngrx/effects";

import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import { NavigationComponent } from "src/core/shared/header-navigation/navigation.component";
import { SidebarComponent } from "src/core/shared/sidebar/sidebar.component";
import { BreadcrumbComponent } from "src/core/shared/breadcrumb/breadcrumb.component";

import { Approutes } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SpinnerComponent } from "src/core/shared/spinner.component";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import {
  NgZorroAntdModule,
  NZ_I18N,
  en_US,
  NzModalRef,
  NzModalService,
} from "ng-zorro-antd";
import en from "@angular/common/locales/en";
import { AuthInterceptor } from "src/core/interceptors/auth.interceptor";
import { CitiesService } from "src/core/services/cities/cities.service";
import { GenericService } from "src/core/services/generic/generic.service";
import { AuthGuard } from "src/core/services/guard/auth-guard.service";
import { StoreModule } from "@ngrx/store";
import {
  metaReducers,
  reducers,
} from "src/app/main/dashboards/dashboard-components/reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "src/environments/environment";
import { RouterState, StoreRouterConnectingModule } from "@ngrx/router-store";
import { SalesReportsEffect } from "src/app/main/dashboards/dashboard1/store/sales-reports/sales-reports.effects";
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { MessageService } from "primeng/api";
import { initializeApp } from "firebase/app";

import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from "ngx-ui-loader";
import { ErrorInterceptor } from "src/core/interceptors/error.interceptor";
import { HttpCancelService } from "src/core/interceptors/httpcancel.service";
import { FinanceReportModule } from "./main/finance/finance-reports/finance-report.module";

initializeApp(environment.firebase);
const config: SocketIoConfig = {
  url: environment.socketUrl,
  options: { secure: true },
};

registerLocaleData(en);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20,
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,

    ReactiveFormsModule,

    HttpClientModule,
    SocketIoModule.forRoot(config),
    // start loader with notification
    NgxUiLoaderModule.forRoot({}),
    NgxUiLoaderHttpModule.forRoot({
      loaderId: "loader",
      showForeground: true,
      exclude: [
        `${environment.endpoint}/RestaurantAttachment/add`,
        `${environment.endpoint}/orders/ordersOperation`,
      ],
    }),
    NgbModule.forRoot(),
    SlimLoadingBarModule.forRoot(),
    RouterModule.forRoot(Approutes, {
      useHash: false,
      scrollPositionRestoration: "enabled",
    }),
    PerfectScrollbarModule,
    NgMultiSelectDropDownModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([SalesReportsEffect]),
    StoreRouterConnectingModule.forRoot({
      stateKey: "router",
      routerState: RouterState.Minimal,
    }),
    NgZorroAntdModule,
    FinanceReportModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    HttpCancelService,
    ,
    AuthGuard,
    GenericService,
    CitiesService,
    MessageService,
    NzModalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
