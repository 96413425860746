import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { getMessaging } from 'firebase-admin/messaging';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {



  constructor(private http: HttpClient) { }

  GetNotifications() {
    return this.http.get<any>(`${environment.endpoint}/notifications.json`).toPromise();
  }

  get() {
    return this.http.get(`${environment.endpoint}/notification/index`).toPromise();
  }

  add(obj) {
    return this.http.post(`${environment.endpoint}/notification/add`, obj).toPromise();
  }

  sendToDevice(title, message, user_id) {
    return this.http.post(`${environment.endpoint}/notification/sendToDevice`,{title, message, user_id}).toPromise();
  }

  edit(obj, id) {
    return this.http.post(`${environment.endpoint}/notification/edit/${id}`, obj).toPromise();
  }

  delete(id) {
    return this.http.get(`${environment.endpoint}/notification/delete/${id}`).toPromise();
  }

  sendNotificationFS(to:string,title,body,image,resturant_id?){



   let data =
   {
    to,
      mutable_content: true,
      data: {
        resturant_id,
        'attachment-url': image
      },
      notification: {
         click_action:"NotificationCategory",
         body,
         title,
         sound:"default"
      }
    }
    return this.http.post('https://fcm.googleapis.com/fcm/send',data,
    {
      headers:{
        Authorization:'key=AAAAcaajcHI:APA91bHJnCKOTcPn2yzNIPaSDw_FIhMqQf9amU-Tf4pCuUeDfNMWvoq_NyVoVPrMWqXhVLv41YgcBzdUuktoJU9VritE_YLKWhxd8nrEcFSK9U9tiymsCP3qG2EogioETZumGUWKtWdR',
        'Content-Type':'application/json'
      }
    }).toPromise()
  }

}
