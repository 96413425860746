import { Component, Input, OnInit } from "@angular/core";
import { UsersService } from "src/core/services/users/users.service";

@Component({
  selector: "app-users-report-count-cart",
  templateUrl: "./users-report-count-cart.component.html",
  styleUrls: ["./users-report-count-cart.component.scss"],
})
export class UsersReportCountCartComponent implements OnInit {
  @Input() counts: any;
  constructor(private service: UsersService) {}

  ngOnInit() {}
}
