import { MenuItemsTopics } from "src/core/models/MenuModel";

export class Cities {
  data: CitiesData;
}

export class CitiesData {
  name: string;
  name_en: string;
  photo: string;

  towns: Towns;
}

export class NotificationData {
  title: string;
  photo: string;
  text: string;

  user_group_id: number;
}

export class Towns {
  create: TownsCreate[];
}

export class TownsCreate {
  name: string;
  name_en: string;
  areas: Areas;
}

export class Areas {
  create: AreasCreate[];
}

export class AreasCreate {
  name: string;
  name_en: string;
  town_id: number;
  city_id?: number;
  google_id: string;
  latitude: number;
  longitude: number;
}

export class MenuCategories {
  name?: string;
  name_en?: string;
  restaurant_id: number;
  id: number;
  photo: string;
  hover?: boolean;
  towns: Towns;
  active: boolean;
}
export class MenuItems {
  id: number;
  name: string;
  photo: string;
  descriptions: string;
  descriptions_en: string;
  name_en: string;
  menu_categories_id: number;
  price: number;
  navigationId?: number;
  menu_options_topics: MenuItemsTopics[] = [];
}

export interface BranchesZones {
  id: number;
  name: string;
  towns: Town[];
}

export interface Town {
  id: number;
  name: string;
  name_en: string;
  created: Date;
  modified: Date;
  city_id: number;
  google_id: string;
  areas: Area[];
}

export interface Area {
  id: number;
  name: string;
  name_en: string;
  created: null;
  modified: null;
  latitude: number;
  longitude: number;
  town_id: number;
  google_id: string;
}
