import { AdsSpacesNameModule } from "./main/marketing/AdsSpacesName/ads-spaces-name.module";
import { FinanceReportModule } from "./main/finance/finance-reports/finance-report.module";
import { NotificationsModule } from "./main/marketing/notifications/notifications.module";
import { Routes } from "@angular/router";
import { CanAccessGuardService } from "src/core/guard/can-access.service";
import { AddIndexEditDeleteModule } from "./main/addIndexEditDelete/add-index-edit-delete.module";
import { FullComponent } from "./layouts/full/full.component";
import { InteractionManagementModule } from "./main/interaction-management/interaction-management.module";
import { MarketingManagementModule } from "src/app/main/marketing-management/marketing-management.module";
import { RestuarantsDetailsModule } from "./main/restaurants/components/restuarants-details/restuarants-details.module";

export const Approutes: Routes = [
  {
    path: "",
    component: FullComponent,
    children: [
      {
        path: "",

        redirectTo: "/authentication/login",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        canActivateChild: [CanAccessGuardService],

        loadChildren: () =>
          import("src/app/main/dashboards/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "new-orders",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import("./main/orders/components/new-orders/new-orders.module").then(
            (m) => m.NewOrdersModule
          ),
      },
      {
        path: "orders",
        loadChildren: () =>
          import("./main/orders/order.module").then((m) => m.OrdersModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "cuisines",
        loadChildren: () =>
          import("./main/cuisines/cuisines.module").then(
            (m) => m.CuisinesModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "admin-setting",
        loadChildren: () =>
          import("./main/settings/admin-setting/admin-setting.module").then(
            (m) => m.AdminSettingModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "mainmenu",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import("./main/min-menu/min-menu.module").then(
            (m) => m.MinMenuModule
          ),
      },
      {
        path: "towns",
        loadChildren: () =>
          import(
            "./main/settings/information/components/towns/towns.module"
          ).then((m) => m.TownsModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "areas",
        loadChildren: () =>
          import(
            "./main/settings/information/components/areas/areas.module"
          ).then((m) => m.AreasModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "cities",
        loadChildren: () =>
          import(
            "./main/settings/information/components/cities/cities.module"
          ).then((m) => m.CitiesModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "branches",
        loadChildren: () =>
          import("./main/branches/branches.module").then(
            (m) => m.BranchesModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "staticpages",
        loadChildren: () =>
          import("./main/marketing/static-pages/static-pages.module").then(
            (m) => m.StaticPagesModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./main/marketing/notifications/notifications.module").then(
            (m) => NotificationsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "restuarantDetails",
        loadChildren: () =>
          import(
            "./main/restaurants/components/restuarants-details/restuarants-details.module"
          ).then((m) => RestuarantsDetailsModule),
      },
      {
        path: "faq",
        loadChildren: () =>
          import("./main/marketing/faq/faq.module").then((m) => m.FaqModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "RestaurantAttachmentTypes",
        loadChildren: () =>
          import(
            "./main/settings/RestaurantAttachmentTypes/attachment-type.module"
          ).then((m) => m.AttachmentTypesModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "contacts",
        loadChildren: () =>
          import("./main/marketing/contacts/contacts.module").then(
            (m) => m.ContactsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "orders-admin",
        loadChildren: () =>
          import(
            "./main/orders/components/orders-admin/orders-admin.module"
          ).then((m) => m.OrdersAdminModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "restaurants",
        loadChildren: () =>
          import("./main/restaurants/restaurants.module").then(
            (m) => m.RestaurantsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      // {
      //   path: 'WalletMerchant',
      //   loadChildren: () => import('./main/wallet-merchant/wallet-merchant.module').then(m => m.WalletMerchantModule),
      //   canActivateChild: [CanAccessGuardService]
      // },
      // {
      //   path: "Complaints",
      //   loadChildren: () =>
      //     import("./main/settings/Complaints/complaints.module").then(
      //       (m) => m.ComplaintsModule
      //     ),
      //   canActivateChild: [CanAccessGuardService],
      // },
      {
        path: "RestaurantStatus",
        loadChildren: () =>
          import(
            "./main/settings/RestaurantStatus/restaurant-status.module"
          ).then((m) => m.RestaurantStatusModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "permissions",
        loadChildren: () =>
          import("./main/permissions/permissions.module").then(
            (m) => m.PermissionsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "RestaurantTypes",
        loadChildren: () =>
          import("./main/settings/RestaurantTypes/restaurant-type.module").then(
            (m) => m.RestaurantTypeModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "RestaurantGeneralStatus",
        loadChildren: () =>
          import(
            "./main/settings/RestaurantGeneralStatus/restaurant-general-status.module"
          ).then((m) => m.RestaurantGeneralStatusModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "RefusedReasons",
        loadChildren: () =>
          import("./main/settings/RefusedReasons/refused-reasons.module").then(
            (m) => m.RefusedReasonsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "Departments",
        loadChildren: () =>
          import("./main/settings/Departments/departments-reasons.module").then(
            (m) => m.DepartmentsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "RefuseApproveReasons",
        loadChildren: () =>
          import(
            "./main/settings/RefuseApproveReasons/refused-reasons.module"
          ).then((m) => m.RefuseApproveReasonModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "user-department",
        loadChildren: () =>
          import(
            "./main/settings/Departments/components/user-departments/users-department.module"
          ).then((m) => m.UserDepartmentModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "approved-department",
        loadChildren: () =>
          import(
            "./main/settings/Departments/components/approved-department/approved-department.module"
          ).then((m) => m.ApprovedDepartmentModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "information",
        loadChildren: () =>
          import("./main/settings/information/information.module").then(
            (m) => m.InformationModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "enternal-notifications",
        loadChildren: () =>
          import("./main/enternal-notifications/notifications.module").then(
            (m) => m.EnternalNotificationsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "delivers",
        loadChildren: () =>
          import("./main/delivers/delivers.module").then(
            (m) => m.DeliverssModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "changePassword",
        loadChildren: () =>
          import("./layouts/changePassword/changePassword.module").then(
            (m) => m.ChangePasswordModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "deliveryDepartment",
        loadChildren: () =>
          import(
            "./main/delivers/components/delivery department/deliveryDepartment.module"
          ).then((m) => m.deliveryDepartmentModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "help",
        loadChildren: () =>
          import("./main/help/help.module").then((m) => m.HelpModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "deliversOrdersCanceled",
        loadChildren: () =>
          import(
            "./main/delivers/components/deliversOrdersCanceled/delivers-orders-caceled.module"
          ).then((m) => m.DeliversOrdersCaceledModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "restaurantApprovalAssginemt",
        loadChildren: () =>
          import(
            "./main/restaurants/components/restaurantApprovalAssginemt/restaurant-approval-assginemt.module"
          ).then((m) => m.RestaurantApprovalAssginemtModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "view-rest",
        loadChildren: () =>
          import(
            "./main/restaurants/components/view-rest/view-rest.module"
          ).then((m) => m.ViewRestModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "DepartmentPositions",
        loadChildren: () =>
          import(
            "./main/settings/DepartmentPositions/department-positions.module"
          ).then((m) => m.DepartmentPositionsModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "WalletSetting",
        loadChildren: () =>
          import("./main/settings/WalletSettings/WalletSettings.module").then(
            (m) => m.WalletsSettingModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "Points",
        loadChildren: () =>
          import("./main/Points/point.module").then((m) => m.PointsModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "PointsSetting",
        loadChildren: () =>
          import("./main/settings/pointSettings/pointSettings.module").then(
            (m) => m.PointSettingsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "attributes-types",
        loadChildren: () =>
          import(
            "./main/settings/attributes-types/attributes-types.module"
          ).then((m) => m.AttributesTypesModule),
      },
      {
        path: "attributeskind",
        loadChildren: () =>
          import("./main/settings/attributes-kind/attributes-kind.module").then(
            (m) => m.attributeskindModule
          ),
      },
      {
        path: "Transactions",
        loadChildren: () =>
          import(
            "./main/finance/Wallets/components/Transactions/transactions.module"
          ).then((m) => m.TransactionsModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "VerificationQuestions",
        loadChildren: () =>
          import(
            "./main/settings/VerificationQuestions/verification-questions.module"
          ).then((m) => m.VerificationQuestionsModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "restaurants-position",
        loadChildren: () =>
          import(
            "./main/restaurants/components/restaurants-position/restaurant-position.module"
          ).then((m) => m.RestaurantPositionModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "rest-attachment",
        loadChildren: () =>
          import(
            "./main/restaurants/components/rest-attachment/rest.module"
          ).then((m) => m.RestModule),
      },
      {
        path: "approveRestaurants",
        loadChildren: () =>
          import(
            "./main/restaurants/components/approve-restaurants/approve-restaurants.module"
          ).then((m) => m.ApproveRestaurantsModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "WalletTypes",
        loadChildren: () =>
          import("./main/settings/WalletTypes/Wallets-Types.module").then(
            (m) => m.WalletsTypeModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "Wallets",
        loadChildren: () =>
          import("./main/finance/Wallets/Wallets.module").then(
            (m) => m.WalletsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      // {
      //   path: "vouchers",
      //   loadChildren: () =>
      //     import("./main/marketing/vouchers/vouchers.module").then(
      //       (m) => m.VoucherModule
      //     ),
      //   canActivateChild: [CanAccessGuardService],
      // },
      {
        path: "mainmenu",
        loadChildren: () =>
          import("./main/settings/mainmenu/mainmenu.module").then(
            (m) => m.MainmenuModule
          ),
      },
      {
        path: "content-approve",
        loadChildren: () =>
          import("./main/content-approve/content-approve.module").then(
            (m) => m.ContentApprovedModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "department-approve",
        loadChildren: () =>
          import(
            "./main/settings/Departments/components/department-approve/department-approve.module"
          ).then((m) => m.DepartmentApproveModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "worketimes",
        loadChildren: () =>
          import(
            "./main/restaurants/components/working-times/working-times.module"
          ).then((m) => m.WorkingTimesModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "branchOrders",
        loadChildren: () =>
          import(
            "./main/branches/components/branch_orders/branch_orders.module"
          ).then((m) => m.BranchOrdersModule),
      },
      {
        path: "restaurant-cuisine",
        loadChildren: () =>
          import(
            "./main/restaurants/components/restaurant-cuisines/restaurant-cuisines.module"
          ).then((m) => m.RestaurantCuisinesModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "wizardSteps",
        loadChildren: () =>
          import("./main/settings/wizardSteps/wizardStepsModule.module").then(
            (m) => m.wizardStepsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "brancheszones",
        loadChildren: () =>
          import(
            "./main/branches/components/branches-zones/branches-zones.module"
          ).then((m) => m.BranchesZonesModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "invoice",
        loadChildren: () =>
          import("./main/finance/invoices/invoice.module").then(
            (m) => m.InvoiceModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "forms",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import("src/app/layouts/form/forms.module").then((m) => m.FormModule),
      },
      {
        path: "attributescategory",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import(
            "./main/settings/attributes-category/attributes-category.module"
          ).then((m) => m.attributescategoryModule),
      },
      {
        path: "apps",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import("src/app/layouts/apps/apps.module").then((m) => m.AppsModule),
      },
      {
        path: "apps/email",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import("src/app/layouts/apps/email/mail.module").then(
            (m) => m.MailModule
          ),
      },
      {
        path: "categories",
        loadChildren: () =>
          import("./main/categories/categories.module").then(
            (m) => m.CategoriesModule
          ),
      },

      {
        path: "offers",
        loadChildren: () =>
          import("./main/marketing/offers/offers.module").then(
            (m) => m.OffersModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "lang",
        loadChildren: () =>
          import("./main/settings/attributes-kind copy/lang.module").then(
            (m) => m.langModule
          ),
      },
      {
        path: "ads-space-price",
        loadChildren: () =>
          import("./main/finance/ads-space-price/ads-space-price.module").then(
            (m) => m.AdsSpacePriceModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "users",
        loadChildren: () =>
          import("./main/users/users.module").then((m) => m.UsersModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "slider",
        loadChildren: () =>
          import("./main/marketing/sliders/sliders.module").then(
            (m) => m.SlidersModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "requests",
        loadChildren: () =>
          import("./main/requests/requests.module").then(
            (m) => m.RequestsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "welcome",
        loadChildren: () =>
          import("./layouts/welcome/welcome.module").then(
            (m) => m.WelcomeModule
          ),
      },
      {
        path: "users-groups",
        loadChildren: () =>
          import("./main/settings/users-groups/users.module").then(
            (m) => m.UsersGroupsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "operations",
        loadChildren: () =>
          import("./main/operations/operations.module").then(
            (m) => m.operationsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "accounts",
        loadChildren: () =>
          import("./main/finance/accounts/accounts.module").then(
            (m) => m.AccountsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "UserCopouns",
        loadChildren: () =>
          import("./main/marketing/UserCopouns/user-copouns.module").then(
            (m) => m.UserCopounsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "CopounSettings",
        loadChildren: () =>
          import("./main/finance/copoun-settings/copoun-settings.module").then(
            (m) => m.CopounSettingsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "AdsSpacesName",
        loadChildren: () =>
          import("./main/marketing/AdsSpacesName/ads-spaces-name.module").then(
            (m) => AdsSpacesNameModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "MarketingManagement",
        loadChildren: () =>
          import(
            "src/app/main/marketing-management/marketing-management.module"
          ).then((m) => MarketingManagementModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "CustomerSupport",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import(
            "./main/settings/customer-support/customer-support.module"
          ).then((m) => m.CustomerSupportModule),
      },

      {
        path: "finance-report",
        loadChildren: () =>
          import("./main/finance/finance-reports/finance-report.module").then(
            (m) => FinanceReportModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "interaction-management",
        loadChildren: () =>
          import(
            "./main/interaction-management/interaction-management.module"
          ).then((m) => InteractionManagementModule),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "AddIndexEditDelete",
        loadChildren: () =>
          import("./main/addIndexEditDelete/add-index-edit-delete.module").then(
            (m) => AddIndexEditDeleteModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      // {
      //   path: 'myvendors',
      //   loadChildren: () => import('./main/my-vendors/my-vendors.module').then(m => MyVendorsModule),
      //   canActivateChild: [CanAccessGuardService]
      // },

      {
        path: "menu-approval",
        loadChildren: () =>
          import("./main/menu-approval/menu-approval.module").then(
            (m) => m.MenuApprovalsModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "vendor-total-finance",
        canActivateChild: [CanAccessGuardService],
        loadChildren: () =>
          import(
            "./main/vendor-total-finance/restaurant-total-finance.module"
          ).then((m) => m.RestaurantTotalFinanceModule),
      },

      {
        path: "mobile-headers",
        loadChildren: () =>
          import("./main/settings/mobile-header/mobile-header.module").then(
            (m) => m.MobileHeaderModule
          ),
        canActivateChild: [CanAccessGuardService],
      },

      {
        path: "logs",
        loadChildren: () =>
          import("./main/logs/logs.module").then((m) => m.LogsModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "ordersforuser",
        loadChildren: () =>
          import(
            "./main/orders/components/orders-for-user/orders-for-user.module"
          ).then((m) => m.OrdersForUserModule),
        canActivateChild: [CanAccessGuardService],
      },
      {
        path: "platform",
        loadChildren: () =>
          import("./main/settings/platform/platform.module").then(
            (m) => m.PlatformModule
          ),
      },
      {
        path: "socialTypes",
        loadChildren: () =>
          import("./main/socialTypes/socialTypes.module").then(
            (m) => m.socialTypesModule
          ),
      },
    ],
  },
  {
    path: "authentication",
    loadChildren: () =>
      import("src/app/main/authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "**",
    redirectTo: "/authentication/404",
  },
];
