import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CategoriesModel } from 'src/core/models/MenuModel';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BacklogsService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get<Array<CategoriesModel>>(`${environment.endpoint}/BackLogs/getBackLogs`).toPromise();
  }

  add(obj) {      
    return this.http.post(`${environment.endpoint}/BackLogs/add`, obj).toPromise();
  }

  edit(obj, id) {
    return this.http.post(`${environment.endpoint}/BackLogs/edit/${id}`, obj).toPromise();
  }   

  delete(id) {
    return this.http.get(`${environment.endpoint}/BackLogs/delete/${id}`).toPromise();
  }    
}

