import { UsersService } from "src/core/services/users/users.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GenericService } from "src/core/services/generic/generic.service";
import { NzModalRef } from "ng-zorro-antd";
import { UserGroup } from "src/core/enums/user.group.enum";
import { SharedService } from "src/core/shared/shared.service";

@Component({
  selector: "app-users-form",
  templateUrl: "./users-form.component.html",
  styleUrls: ["./users-form.component.css"],
})
export class UsersFormComponent implements OnInit {
  form: FormGroup;
  users: any[] = [];
  user: any = null;
  id: any;
  isAdd: boolean = false;
  GroupId? = 0;
  BranchId? = 0;
  userGroup = UserGroup;

  constructor(
    private fb: FormBuilder,
    private service: UsersService,
    private modal: NzModalRef,
    private navigate: Router,
    private generic: GenericService,
    private router: ActivatedRoute,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.createForm();
    this.getUsers();
    this.user = localStorage.getItem("item");

    if (this.user) {
      const user = JSON.parse(this.user);
      this.id = user.id;
      user.username = user.username.split("@")[0];
      this.form.reset(user);
    }
  }
  UserObject(newName: string) {
    const user = JSON.parse(newName);
    this.id = user.id;
    user.username = user.username.split("@")[0];
    this.form.reset(user);
    this.isAdd = false;
    this.user = user;
    console.log(user);
  }

  createForm() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      mobile: [null, Validators.required],
      username: [" "],
      password: ["sata@123"],
      user_group_id: [null],
      name: [null, Validators.required],
      position: [null],
      branch_id: [null],
      code: [this.sharedService.generateCode(10)],
    });
  }

  async getUsers() {
    const users = await this.service.getUserGroups();
    this.users = users as any;
    console.log(users);
  }

  async addUser() {
    if (this.GroupId != 0) {
      this.form.get("branch_id").setValue(this.BranchId);

      this.form.get("user_group_id").setValue(this.GroupId);
    }
    if (this.form.valid) {
      this.isAdd = true;
      // this.form.use
      this.form.controls["username"].setValue(
        this.form.controls["username"].value + "@sata.com"
      );
      const data = await this.service.addUser(this.form.getRawValue());
      this.isAdd = false;
      this.generic.showNotification("success", "اضافة", "تمت العملية بنجاح");
      if (!this.navigate.url.includes("restuarantDetails")) {
        this.navigate.navigateByUrl("/users/all");
      }
      this.form.reset();
    }
  }

  async editUser() {
    if (this.form.valid) {
      this.form.controls["username"].setValue(
        this.form.controls["username"].value + "@sata.com"
      );
      const form = this.form.getRawValue();
      delete form.password;
      const data = await this.service.editUser(form, this.id);
      this.isAdd = false;
      this.generic.showNotification("success", "تعديل", "تمت العملية بنجاح");
      if (!this.navigate.url.includes("restuarantDetails")) {
        this.navigate.navigateByUrl("/users/all");
      }
      this.modal.close();
    }
  }

  isUser;
  isUserUsed = false;
  isEmail;
  isMobile;
  isEmailUsed = false;
  isMobileUsed = false;

  async validateUniq(value, type) {
    if (value) {
      if (type == "email") this.isEmail = true;
      if (type == "username") this.isUser = true;
      if (type == "mobile") this.isMobile = true;
      this.service
        .userSearch({
          username: type == "username" ? value + "@sata.com" : value,
        })
        .then((res) => {
          if (res) {
            if (type == "email") this.isEmailUsed = true;
            if (type == "username") this.isUserUsed = true;
            if (type == "mobile") this.isMobileUsed = true;
          } else {
            if (type == "email") this.isEmailUsed = false;
            if (type == "username") this.isUserUsed = false;
            if (type == "mobile") this.isMobileUsed = false;
          }
          if (type == "email") this.isEmail = false;
          if (type == "username") this.isUser = false;
          if (type == "mobile") this.isMobile = false;
        });
    } else {
      if (type == "email") this.isEmail = false;
      if (type == "mobile") this.isMobile = false;
      if (type == "username") this.isUser = false;
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem("item");
  }
}
