import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrdersAdminService {

  constructor(private http: HttpClient) { }

  get(params = {}) {
    return this.http.get(`${environment.endpoint}/orders/index`,
    {params}
    ).toPromise();
  }

  userSearchForCustomerSupport(obj,params = {}) {
    return this.http.post(`${environment.endpoint}/users/UserSearchForCustomerSupport`, obj,

    ).toPromise();
  }

  filter(obj,params = {}) {
    return this.http.post(`${environment.endpoint}/orders/index`, obj,
    {params}
    ).toPromise();
  }

  delete(id) {
    return this.http.get(`${environment.endpoint}/faq/delete/${id}`).toPromise();
  }

  add(obj) {
    return this.http.post(`${environment.endpoint}/faq/add`, obj).toPromise();
  }

  edit(obj, id) {
    return this.http.post(`${environment.endpoint}/faq/edit/${id}`, obj).toPromise();
  }

  getReport(data = {}){
    return this.http.post(`${environment.endpoint}/orders/getorderReporForAdmin`,data).toPromise()
  }

}
