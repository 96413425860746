import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { GenericService } from "src/core/services/generic/generic.service";
import { Router } from "@angular/router";
import { FaqService } from "src/core/services/faqs/faq.service";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
})
export class EditComponent implements OnInit {
  form: FormGroup;
  cats: any[] = [];
  brands: any[] = [];
  obj: any = null;
  id: any;
  isAdd: boolean = false;

  constructor(
    private fb: FormBuilder,
    private service: FaqService,
    private generic: GenericService,
    private navigate: Router
  ) {}

  ngOnInit() {
    this.createForm();

    this.obj = localStorage.getItem("item");

    if (this.obj) {
      const obj = JSON.parse(this.obj);
      this.id = obj.id;
      this.form.reset(obj);
    }
  }

  createForm() {
    this.form = this.fb.group({
      title: [null, Validators.required],
      title_en: [null, Validators.required],
    });
  }

  async edit() {
    if (this.form.valid) {
      const obj = this.form.getRawValue();
      obj.id = this.id;
      this.isAdd = true;
      const data = await this.service.edit(this.form.getRawValue(), this.id);
      this.isAdd = false;
      this.generic.showNotification("success", "تعديل", "تمت العملية بنجاح");
      this.navigate.navigateByUrl("/AddIndexEditDelete/index");
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem("item");
  }
}
