import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NzModalService } from "ng-zorro-antd";
import { UserGroup } from "src/core/enums/user.group.enum";
import { CuisinesService } from "src/core/services/cuisines/cuisines.service";
import { GenericService } from "src/core/services/generic/generic.service";
import { restaurant_cuisines } from "src/core/services/restaurants/RestaurantModel";
import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";
import { AddCuisines } from "./add/AddCuisines.component";

@Component({
  selector: "app-restaurant-cuisines",
  templateUrl: "./restaurant-cuisines.component.html",
  styleUrls: ["./restaurant-cuisines.component.css"],
})
export class RestaurantCuisinesComponent implements OnInit {
  resId;
  heads = ["الاسم", "الاسم انجليزي"];
  dataarray: restaurant_cuisines[];
  whatUser: any = localStorage.getItem("user_group_id");
  userGroup = UserGroup;

  constructor(
    private service: RestaurantsService,
    public NZmodalService: NzModalService,
    private navigate: Router,
    private cuisinesService: CuisinesService,
    public generic: GenericService,
    private activatedRoute: ActivatedRoute
  ) {}
  async get() {
    this.resId = this.activatedRoute.snapshot.paramMap.get("id");
    const data = await this.service.getRestCuisines(this.resId);
    this.dataarray = data;
  }
  async ngOnInit() {
    this.get();
  }
  openModal() {
    const modalRef = this.NZmodalService.create({
      nzTitle: "اضف اختصاص للمطعم",
      nzFooter: " ",
      nzContent: AddCuisines,
      nzWidth: "800px",
    });

    setTimeout(() => {
      const data: any = this.dataarray.map((d) => d.cuisine_id);
      const instance = modalRef.getContentComponent();
      instance.resId = this.resId;
      instance.restaurant_cuisines.cuisine_id = data;
    }, 0.005);

    modalRef.afterClose.subscribe((result) => {
      this.get();
    });
  }
  async delete(id, index) {
    this.service.DeletRestCuisines(id).then((res) => {
      this.dataarray.splice(index, 1);
      this.generic.showNotification("success", "حذف", "تمت العملية بنجاح");
    });
  }
}
