import { Routes } from "@angular/router";
import { InterManagApproveComponent } from "./index-inter-dep-approve/index-inter-dep-content-approve.component";

export const InteractionDepRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "all",
        component: InterManagApproveComponent,
        data: {
          title: "ادارة التفاعل",
        },
      },
    ],
  },
];
