import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Restuarant } from "src/core/services/restaurants/RestaurantModel";
import Swal from "sweetalert2";
import { DepartmentsEnum } from "src/core/enums/departments.enum";
import { GeneralStatus } from "src/core/enums/generalStatus.enum";
import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-vendor-cell",
  templateUrl: "./vendor-cell.component.html",
  styleUrls: ["./vendor-cell.component.scss"],
})
export class VendorCellComponent implements OnInit {
  @Input() restaurants: Restuarant[] = [];
  @Input() btns: any;
  URL = environment.photoPath;
  imageSrc: any = "../../assets/images/user.png";
  CoverSrc: any = "assets/images/noimage.jpeg";
  departmentEnum = DepartmentsEnum;
  generalStatus = GeneralStatus;

  constructor(private service: RestaurantsService) {}

  ngOnInit() {}

  BackToDepartment(i) {
    if (
      this.restaurants[i].departments_id == this.departmentEnum.quality_check
    ) {
      this.restaurants[i].restaurant_general_status_id =
        this.generalStatus.SetMenuStatus;
    } else if (
      this.restaurants[i].departments_id == this.departmentEnum.Training ||
      this.restaurants[i].departments_id == this.departmentEnum.activate
    ) {
      this.restaurants[i].restaurant_general_status_id =
        this.generalStatus.ActivationStatus;
    }

    this.EditRest(i);
  }
  Archive(i) {
    this.restaurants[i].archived = 1;

    this.EditRest(i);
  }
  async EditRest(i) {
    //if (new validations(document).vlidate()){
    var formData: any = new FormData();

    formData.append("data", JSON.stringify(this.restaurants[i]));

    const data = await this.service.add(formData);
    console.log(data);
    if (data.id > 0 || data > 0) {
      Swal.fire({
        title: "Success!",
        text: "Data has been Upadated",
        icon: "success",
        confirmButtonColor: "#0162e8",
      });
    } else {
      // this.toastService.showToast('error', "لم يتم التسجيل","لم يتم التسجيل")
    }
  }

  resReport(name: string, id: number, type: string) {
    if (type == "report")
      window.open(`/#/restaurants/rep-inv/${id}/${name}`, "_blank");
    if (type == "details") window.open(`/#/restuarantDetails/${id}`, "_blank");
  }
}
