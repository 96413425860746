import { AddComponent } from "src/app/main/branches/add/add.component";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BillsService } from "src/core/services/bills/bills.service";
import { BranchesService } from "src/core/services/branches/branches.service";
import { ConfigrationsService } from "src/core/services/configrations/configrations.service";
import { FinanceReportsService } from "src/core/services/finance-reports/finance-reports.service";
import { GenericService } from "src/core/services/generic/generic.service";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import * as moment from "moment";
import { DepartmentsEnum } from "src/core/enums/departments.enum";
import { NzModalService } from "ng-zorro-antd";

@Component({
  selector: "app-information-index",
  templateUrl: "./financeIndexComponent.component.html",
  styleUrls: ["./financeIndexComponent.component.scss"],
})
export class FinanceIndexComponent implements OnInit {
  isShow: boolean;
  configrations: any[] = [];
  form: FormGroup;
  branchId: any;
  id: any;
  name: any;
  branches: any[] = [];

  loader: boolean = false;

  totMonthSales: any = 0;
  totWeekSales: any = 0;

  payments: any[] = [];
  expenses: any[] = [];
  monthordersForchart: any[] = [];
  weekordersForchart: any[] = [];
  commission: any[] = [];
  userId = JSON.parse(localStorage.getItem("user_group_id"));
  departmentEnum = DepartmentsEnum;

  heads: any[] = ["الطبق", "القيمة", "المجموع"];
  orderDetails: any[] = [];

  percentage: number = 0;

  deserved: any[] = [];

  deservedHead: any[] = [
    "رقم الفاتورة",
    "المطعم",
    "الفرع",
    "من تاريخ",
    "الى تاريخ",
    " عدد الطلبات",
    " اجمالي الطلبات",
    "حالة الدفع",
    "نوع الفاتورة",
    "المطلوب دفعه",
    "الادوات",
  ];

  bills: any[] = [];
  billsHead: any[] = [
    "رقم الفاتورة",
    "المطعم",
    "الفرع",
    "من تاريخ",
    "الى تاريخ",
    " عدد الطلبات",
    "حالة الدفع",
    "نوع الفاتورة",
    "المطلوب دفعه",
    "الادوات",
  ];
  unpaidBills: number = 0;
  paidBills: number = 0;

  date: any[] = [];
  filtred: any[] = [];
  filtredHeads = [
    "#",
    "من تاريخ",
    "الى تاريخ",
    "عدد الطلبات",
    "نوع الفاتورة",
    "المطلوب دفعه",
    "الادوات",
  ];

  dates: any[] = [];

  isRestaurant =
    localStorage.getItem("RestaurantId") &&
    localStorage.getItem("RestaurantId") != "null"
      ? true
      : false;

  invoiceFor: any = {};
  from: any;
  to: any;
  invoiceType: string = "";
  invoiceTotal: number = 0;
  currentInvoice: any;

  // New
  count = {
    visaOrders: null,
    CashOrders: null,
    Ordertotals: null,
    ordercount: null,
    discount: null,
    commission: null,
    WalletsDiscountTotal: null,
    OffersDiscountTotal: null,
    TotalClientCancel: null,
    TotalVendorCancel: null,
    CountClientCancel: null,
    COUNTVendorCancel: null,
  };

  constructor(
    private fr_ser: FinanceReportsService,
    private service: BranchesService,
    private route: ActivatedRoute,
    private billsService: BillsService,
    public generic: GenericService,
    private router: Router,
    private fb: FormBuilder,
    private configrationsService: ConfigrationsService,
    private NZmodalService: NzModalService
  ) {
    this.isShow = true;
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.name = this.route.snapshot.paramMap.get("name");
    this.createForm();
    this.get();
    this.getBranches();
    this.getReport();
    this.getorderReportForBranch();
    this.getBills();
  }

  createForm() {
    this.form = this.fb.group({
      branchId: [null],
    });
  }

  async get() {
    const data: any = await this.configrationsService.get();
    this.configrations = data;
  }

  // async getBranches(id: any) {
  //   this.branches = await this.service.getRestaurant(id).toPromise();
  //   console.log(this.branches)
  //   this.form.get('branchId').setValue(this.branches[0].id || null)
  // }
  async getBranches() {
    await this.fr_ser
      .get_finance_reports_indexForAdmin()
      .then((data: any) => {
        this.branches = data;
      })
      .catch((err) => {
        console.log("error");
      });

    console.log("test ", this.branches);
    this.form
      .get("branchId")
      .setValue(this.branches.length ? this.branches[0].id : null);
  }

  onTabChange() {}

  getFiltredBills() {
    const obj = {
      from: this.generic.formatDate(this.date[0], "filter"),
      to: this.generic.formatDate(this.date[1], "filter"),
    };

    this.loader = true;

    this.service.filtrOrdersForBills(this.branchId, obj).subscribe(
      (res: any) => {
        this.filtred = res;
        this.loader = false;
      },
      () => {
        this.loader = false;
        this.generic.showNotification(
          "error",
          "حدث خطأ",
          "بالؤجاء المحاولة في وقت لاحق"
        );
      }
    );
  }

  disabledDate(current) {
    const formated = moment(current).format("YYYY-MM-DD");
    const dates: any[] = JSON.parse(localStorage.getItem("dates"));

    if (dates.includes(formated)) return true;

    return false;
  }

  async getReport() {
    const data: any = await Promise.all([
      this.fr_ser.get_finance_reports_getorderReportForAdminCounts(),
      this.fr_ser.getCancelationOrderReport(),
      this.fr_ser.getCommission(),
    ]);

    this.count = { ...data[0].weekorders[0], ...data[1].report[0] };
    this.commission = data[2].weekorders;

    // this.totWeekSales = res.monthorders[0].weekorders;

    // this.payments = res.OrderByPayment;
    // this.monthordersForchart = res.monthordersForchart;
    // this.weekordersForchart = res.weekordersForchart;
    // this.orderDetails = res.orderdetails;

    // this.percentage = parseFloat(res.Confirgrations[0].value);
  }

  createInvoice(item: any) {
    this.loader = true;

    const bill = {
      branch_id: this.branchId,
      confirmed: 0,
      date_from: this.generic.formatDate(this.date[0], "filter"),
      date_to: this.generic.formatDate(this.date[1], "filter"),
      total: item.Ordertotals,
      type: item.commission - item.visaOrders < 0 ? 1 : 0,
    };

    this.billsService.addBill(bill).subscribe(
      (res) => {
        this.loader = false;
        item.month = this.generic.formatDate(this.date[1], "filter");
        localStorage.setItem("invoiceFor", JSON.stringify(item));
        this.router.navigate([`invoice/create`]);
      },
      () => {
        this.loader = false;
        this.generic.showNotification(
          "error",
          "حدث خطأ",
          "بالؤجاء المحاولة في وقت لاحق"
        );
      }
    );
  }

  getFromDate(month: string) {
    const date: any[] = month.split("-");
    const today = date[2];
    date[2] = today - date[2] + 1;

    return date.join("-");
  }

  printPageArea(invoice) {
    this.invoiceData(invoice);
    this.loader = true;

    this.currentInvoice = invoice;
    setTimeout(() => {
      let data = document.getElementById("contentToConvert");
      data.classList.remove("d-none");
      html2canvas(data as any).then((canvas) => {
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
        const pdfData = new jsPDF("p", "mm", "a4");
        const position = 0;

        pdfData.addImage(
          contentDataURL,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight
        );
        pdfData.autoPrint();
        window.open(URL.createObjectURL(pdfData.output("blob")), "_blank");
        data.classList.add("d-none");

        this.loader = false;
      });
    }, 10);
  }

  captureScreen(invoice) {
    this.invoiceData(invoice);
    this.loader = true;

    setTimeout(() => {
      let data = document.getElementById("contentToConvert");
      data.classList.remove("d-none");
      html2canvas(data as any).then((canvas) => {
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        const contentDataURL = canvas.toDataURL("image/png");
        let pdfData = new jsPDF("p", "mm", "a4");
        var position = 0;
        pdfData.addImage(
          contentDataURL,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight
        );
        pdfData.save(`${this.name}invoice.pdf`);
        data.classList.add("d-none");

        this.loader = false;
      });
    }, 10);
  }

  invoiceData(invoice) {
    console.log("invoice ", invoice);

    this.invoiceFor = invoice;

    if (!(this.invoiceFor["month"] in this.invoiceFor)) {
      this.invoiceFor.month = this.generic.formatDate(
        invoice.date_to,
        "filter"
      );
    }

    const date: any[] = this.invoiceFor.month.split("-");
    const today = date[2];

    date[2] = today - date[2] + 1;
    this.from = date.reverse().join("-");

    date[0] = today;
    this.to = date.join("-");

    this.invoiceTotal = this.invoiceFor.commission - this.invoiceFor.visaOrders;
    this.invoiceType = this.invoiceTotal < 0 ? "صادر" : "وارد";
    this.invoiceTotal *= -1;

    return true;
  }

  async confirmBill(id: number, i) {
    const res = await this.billsService.editBill(id, { confirmed: 1 });
    if (res) {
      this.generic.showNotification(
        "success",
        "edit bill",
        "bill edited successfuly"
      );
      this.bills[i].confirmed = 1;
    } else {
      this.generic.showNotification(
        "error",
        "edit bill",
        "something wrong error"
      );
    }
  }

  getorderReportForBranch() {
    this.fr_ser.get_finance_reports_getorderReportForAdmin().then(
      (res: any) => {
        this.deserved = res.weekorders;
      },
      () => {
        this.loader = false;
        this.generic.showNotification(
          "error",
          "حدث خطأ",
          "بالؤجاء المحاولة في وقت لاحق"
        );
      }
    );
  }

  getBills() {
    this.fr_ser.get_finance_reports_indexForAdmin().then(
      (res: any) => {
        this.bills = res;

        for (let i = 0; i < this.bills.length; i++) {
          this.dates.push(this.bills[i].date_from);
          this.dates.push(this.bills[i].date_to);
        }
        localStorage.setItem("dates", JSON.stringify(this.dates));
        this.unpaidBills = this.bills.filter((bill) => !bill.confirmed).length;
        this.paidBills = this.bills
          .filter((bill) => bill.confirmed)
          .map((bill) => bill.total)
          .reduce((prev, next) => prev + next, 0);
        this.loader = false;
      },
      () => {
        this.loader = false;
        this.generic.showNotification(
          "error",
          "حدث خطأ",
          "بالؤجاء المحاولة في وقت لاحق"
        );
      }
    );
  }

  createDeservedInvoice(item: any) {
    this.loader = true;

    const bill = {
      branch_id: item.branch_id,
      confirmed: 0,
      date_from: this.GetFromDate(item.month),
      date_to: item.month,
      total: item.Ordertotals,
      type: item.commission - item.visaOrders < 0 ? 1 : 0,
    };
    localStorage.setItem("invoiceFor", JSON.stringify(item));
    this.router.navigate([`invoice/create`]);

    this.billsService.addBill(bill).subscribe(
      (res) => {
        this.loader = false;
      },
      () => {
        this.loader = false;
        this.generic.showNotification(
          "error",
          "حدث خطأ",
          "بالؤجاء المحاولة في وقت لاحق"
        );
      }
    );
  }

  GetFromDate(month: string) {
    if (month) {
      const date: any[] = month.split("-");
      const today = date[2];
      date[2] = today == 15 ? 1 : 15;

      return date.join("-");
    }
  }

  openDetailsModal(branch) {
    const modalRef = this.NZmodalService.create({
      nzTitle: "تفاصيل الفرع",
      nzFooter: "  ",
      nzContent: AddComponent,
      nzWidth: "800px",
    });

    setTimeout(() => {
      const instance = modalRef.getContentComponent();
      instance.BranchObj = branch;
    }, 3000);
  }
}
