import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddComponent } from './add/add.component';
import { IndexComponent } from './index/index.component';


const routes: Routes = [  {
  path: '',
  children: [
    {
      path: 'add',
      component: AddComponent,
    },
    {
        path: 'index',
        component: IndexComponent ,
        data: {
          title: 'أماكن الاعلانات',
        }            
    },       
      
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdsSpacesNameRoutingModule { }
