import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DepartmentsService {
  constructor(private http: HttpClient) {}

  get() {
    return this.http
      .get<any[]>(`${environment.endpoint}/Departments/index`)
      .toPromise();
  }

  add(obj) {
    return this.http
      .post(`${environment.endpoint}/Departments/add`, obj)
      .toPromise();
  }

  edit(user, id) {
    return this.http
      .post(`${environment.endpoint}/Departments/edit/${id}`, user)
      .toPromise();
  }

  delete(id) {
    return this.http
      .get(`${environment.endpoint}/Departments/delete/${id}`)
      .toPromise();
  }

  getAllDepartmentEmployee(id) {
    return this.http
      .get(`${environment.endpoint}/departments/GetAllDepartmentEmployee/${id}`)
      .toPromise();
  }
}
