import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { options } from 'src/app/options';
import { AdsModel } from './AdsModel';


@Injectable({
  providedIn: 'root'
})
export class AdsService {

  constructor(private http: HttpClient) { }

  GetAd() {
    return this.http.get<Array<AdsModel>>(`${environment.endpoint}/AdsSpacePrices/index`).toPromise();
  }
 
  addAd(ad) {
    return this.http.post(`${environment.endpoint}/AdsSpacePrices/add`, ad);
  }

  editAd(ad, id) {
    return this.http.post(`${environment.endpoint}/AdsSpacePrices/edit/${id}`, ad);
  }

  editForDepartment(offer, id) {
    return this.http.post(`${environment.endpoint}/AdsSpacePrices/editForDepartment/${id}`, offer);
  }

  deleteOffer(id) {
    return this.http.get(`${environment.endpoint}/AdsSpacePrices/delete/${id}`);
  }
}
