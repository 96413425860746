import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/core/module/shared.module";
import { AddIndexEditDeleteRoutingModule } from "./add-index-edit-delete-routing.module";
import { AddComponent } from "./add/add.component";
import { IndexComponent } from "./index/index.component";
import { EditComponent } from "./edit/edit.component";
import { NzFormModule } from "ng-zorro-antd/form";

@NgModule({
  declarations: [AddComponent, IndexComponent, EditComponent],
  imports: [
    CommonModule,
    AddIndexEditDeleteRoutingModule,
    SharedModule,
    NzFormModule,
  ],
})
export class AddIndexEditDeleteModule {}
