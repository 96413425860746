import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTabsModule } from "@angular/material";
import {
  NgZorroAntdModule,
  NzAlertModule,
  NzAutocompleteModule,
  NzAvatarModule,
  NzBreadCrumbModule,
  NzCardModule,
  NzEmptyModule,
  NzIconModule,
  NzMessageModule,
  NzModalModule,
  NzPaginationModule,
  NzSwitchModule,
  NzTimePickerModule,
} from "ng-zorro-antd";
import { BarChartComponent } from "src/app/main/dashboards/dashboard-components/bar-chart/bar-chart.component";
import { AdressPopupComponent } from "src/app/main/orders/order-report/adress-popup/adress-popup.component";
import { PopupDetailsComponent } from "src/app/main/orders/order-report/popup-details/popup-details.component";
import { PrimengModule } from "src/core/shared/primeng/primeng.module";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { AntModule } from "./ant.module";
import { MatModule } from "./mat.module";
import { PipesModule } from "./pipes.module";
import { MessageService } from "primeng/api";
import { EditComponent } from "src/app/main/restaurants/add/edit/edit.component";
import { UsersFormComponent } from "src/app/main/users/users-form/users-form.component";
import { UsersIndexComponent } from "src/app/main/users/users-index/users-index.component";
import { NzTagModule } from "ng-zorro-antd/tag";
import { AgmDirectionModule } from "agm-direction";
import { PermessionsDirective } from "src/core/directive/permessions.directive";
import { UsersReportCountCartComponent } from "src/app/main/users/users-report-count-cart/users-report-count-cart.component";
import { UserFilterComponent } from "src/app/main/users/user-filter/user-filter.component";
import { RouterModule } from "@angular/router";
import { UserReportComponent } from "src/app/main/users/user-report/user-report.component";
import { OrderAdminIndexComponent } from "src/app/main/orders/components/orders-admin/order-admin-index/order-admin-index.component";
import { MainReportComponent } from "src/app/main/orders/components/orders-admin/main-report/main-report.component";
import { OrderAdminFilterComponent } from "src/app/main/orders/components/orders-admin/order-admin-filter/order-admin-filter.component";
import { UserReportFilterComponent } from "src/app/main/users/user-report-filter/user-report-filter.component";
import { MainMenuIndexComponent } from "src/app/main/min-menu/index/index.component";
import { AddCategoryModalComponent } from "src/app/main/min-menu/add-category-modal/add-category-modal.component";
import { ApproveRestaurantsComponent } from "src/app/main/restaurants/components/approve-restaurants/approve-restaurants.component";
import { AddComponent } from "src/app/main/branches/add/add.component";
import { ImageComponent } from "src/core/shared/image/image.component";
import { VendorCellComponent } from "src/app/main/restaurants/components/vendor-cell/vendor-cell.component";
import { AttachmentComponent } from "src/app/main/restaurants/components/rest-attachment/attachment/attachment.component";
import { CardDetailsComponent } from "src/app/main/restaurants/components/restuarants-details/Card-Details/card-details.component";

@NgModule({
  declarations: [
    PopupDetailsComponent,
    AdressPopupComponent,
    BarChartComponent,
    EditComponent,
    UsersFormComponent,
    OrderAdminIndexComponent,
    UsersIndexComponent,
    PermessionsDirective,
    UserReportComponent,
    UserFilterComponent,
    UsersReportCountCartComponent,
    UserReportFilterComponent,
    OrderAdminIndexComponent,
    OrderAdminFilterComponent,
    MainReportComponent,
    MainMenuIndexComponent,
    AddCategoryModalComponent,
    CardDetailsComponent,
    AddComponent,
    ImageComponent,
    ApproveRestaurantsComponent,
    AttachmentComponent,
    VendorCellComponent,
  ],
  imports: [
    CommonModule,
    AntModule,
    PipesModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    NzTabsModule,
    NzMessageModule,
    NzAlertModule,
    NzIconModule,
    NzModalModule,
    NzAutocompleteModule,
    NzEmptyModule,
    NzTagModule,
    PrimengModule,
    NzBreadCrumbModule,
    NzSwitchModule,
    NzTimePickerModule,
    RouterModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyD_U2-w1Zald01AAXfi4vO6EH35YYc0-hM",
      libraries: ["places"],
    }),
    AgmDirectionModule, // agm-direction
    NzPaginationModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UsersFormComponent,
    NzTabsModule,
    NzAvatarModule,
    NzSwitchModule,
    NzTimePickerModule,
    PopupDetailsComponent,
    AntModule,
    MatModule,
    NzModalModule,
    NzCardModule,
    MatTabsModule,
    NzAutocompleteModule,
    AgmDirectionModule, // agm-direction
    OrderAdminIndexComponent,
    OrderAdminFilterComponent,
    MainReportComponent,
    PrimengModule,
    NzIconModule,
    PipesModule,
    NzEmptyModule,
    AgmCoreModule,
    NzBreadCrumbModule,
    AdressPopupComponent,
    UsersIndexComponent,
    NzTagModule,
    BarChartComponent,
    EditComponent,
    PermessionsDirective,
    MainMenuIndexComponent,
    AddCategoryModalComponent,
    CardDetailsComponent,
    AddComponent,
    ImageComponent,
    ApproveRestaurantsComponent,
    AttachmentComponent,
    VendorCellComponent,
    NzPaginationModule,
    UserFilterComponent,
    UsersReportCountCartComponent,
  ],
  providers: [GoogleMapsAPIWrapper, MessageService],
})
export class SharedModule {}
