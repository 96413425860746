import { Component, OnInit } from "@angular/core";
import { AdsSpacesNameService } from "src/core/services/ads-space-name/ads-spaces-name.service";
import { GenericService } from "src/core/services/generic/generic.service";
import { EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"],
})
export class IndexComponent implements OnInit {
  AdsSpacesName: any[] = [];
  data: any[] = [];
  heads = ["#", "الاسم", "تاريخ الانشاء", "actions"];

  loading: boolean = false;
  cities: any[] = [];
  cityID = null;

  @Output() editRow: EventEmitter<any>;

  constructor(
    private service: AdsSpacesNameService,
    private generic: GenericService,
    private router: Router
  ) {
    this.editRow = new EventEmitter();
  }

  async ngOnInit() {
    this.get();
  }
  async get() {
    const data = await this.service.GetAdsSpacesName();
    console.log(data);
    this.data = data;
  }
  edit(item) {
    const obj = JSON.stringify(item);
    localStorage.setItem("item", obj);
    // this.router.navigate([`areas/edit`]);
    this.editRow.emit(true);
  }

  async delete(id, index) {
    this.loading = true;
    this.service
      .delete(id)
      .then((res) => {
        this.AdsSpacesName.splice(index, 1);
        this.loading = false;
        this.get();

        this.generic.showNotification("success", "حذف", "تمت العملية بنجاح");
      })
      .catch((err) => {
        this.loading = false;
        this.generic.showNotification(
          "success",
          "حذف",
          "  لا يمكن الحذف لارتباطه بمنطقه"
        );
      });
  }

  filter(id: number) {
    if (id) {
      const filter = this.data.filter((item) => item.towns.id == id);
      this.cityID = id;
      this.AdsSpacesName = filter;
      return;
    }

    this.AdsSpacesName = this.data;
  }
}
