import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { DepartmentsService } from "src/core/services/departments/departments.service";
import { GenericService } from "src/core/services/generic/generic.service";
import { AntModule } from "src/core/module/ant.module";
import { UserGroup } from "src/core/enums/user.group.enum";
import { EnternalNotificationsService } from "src/core/services/enternal-notifications/enternal-notifications.service";
import { DepartmentsEnum } from "src/core/enums/departments.enum";
import { NotificationsService } from "src/core/services/notifications/notifications.service";

@Component({
  selector: "app-approve-restaurants",
  templateUrl: "./approve-restaurants.component.html",
  styleUrls: ["./approve-restaurants.component.scss"],
})
export class ApproveRestaurantsComponent implements OnInit {
  y;
  @Input("IsManager") IsManager: boolean = false;
  data: any[] = [];
  department = DepartmentsEnum;
  heads = [
    "#",
    "المطعم",
    "الترتيب",
    "تاريخ البداية",
    "تاريخ النهاية",
    "رسالة الادارة",
    "الحالة",
    "approved",
  ];

  loading: boolean;
  URL = environment.photoPath;
  isDep: boolean = false;
  selected: any;
  dep: any[];
  isRef: AntModule;
  reason: string;
  userGroup = UserGroup;
  res_id: any;

  constructor(
    private service: RestaurantsService,
    private departmentsService: DepartmentsService,
    private generic: GenericService,
    private notificationService: EnternalNotificationsService,
    private _NotificationsService: NotificationsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.get();
    this.getDep();
  }

  async get() {
    if (this.IsManager) {
      const data: any = await this.service.indexForAccountManager();
      this.data = data;
    } else {
      const data: any = await this.service.indexForDepartmentApprove();
      this.data = data;
    }

    console.log(this.data, "first");
  }

  async getDep() {
    const data: any = await this.departmentsService.get();
    this.dep = data;
  }

  async setDep(dataa: any) {
    let publish = 0;

    if (dataa["approval"] == 1) {
      publish = 1;
    }
    const obj = {
      department_id: this.selected,
      department_message: this.reason,
      approval: 1,
      publish: publish,
    };

    console.log(obj);
    console.log(this.selected);
    const data = await this.service.editApprove(obj, this.res_id);

    if (data) {
      this.generic.showNotification("success", "موافقه", "تمت العملية بنجاح");
      console.log(dataa);
      this.SendNotification(
        dataa.restaurant_id,
        dataa.restuarants.departments_id,
        dataa.restuarants.account_manager_id,
        dataa.restuarants.name
      );
      this._NotificationsService.sendNotificationFS(
        `/topics/null`,
        this.department.markting,
        "ترتيب المطعم",
        "تمت الموافقة على ترتيب مطعم جديد"
      );
      this.selected = null;
      this.isDep = false;
      this.get();
    }
  }

  async RefAds() {
    const obj = {
      department_id: this.selected,
      department_message: this.reason,
      approval: 2,
    };

    const data = await this.service.editApprove(obj, this.res_id);

    if (data) {
      this.generic.showNotification("success", "موافقه", "تمت العملية بنجاح");
      this.selected = null;
      this.isRef = false;

      this.get();
    }
  }
  async SendNotification(
    restaurant_id,
    department_id,
    account_manager_id,
    name
  ) {
    const ConTentNotify = await this.notificationService.add({
      restuarant_id: restaurant_id,
      department_id: department_id,
      user_id: account_manager_id,
      title: "ترتيب المطعم",
      message: "تمت الموافقة على ترتيب مطعم جديد  " + name,
    });
  }
}
