import { ActivatedRoute } from "@angular/router";
import { Socket } from "ngx-socket-io";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";
import { RestuarantsDetailsComponent } from "../restuarants-details/restuarants-details.component";
import { UserGroup } from "src/core/enums/user.group.enum";
import { DepartmentsEnum } from "src/core/enums/departments.enum";
import { GeneralStatus } from "src/core/enums/generalStatus.enum";

@Component({
  selector: "app-restuarants-details-tabs",
  templateUrl: "./restuarants-details-tabs.component.html",
  styleUrls: ["./restuarants-details-tabs.component.scss"],
})
export class RestuarantsDetailsTabsComponent implements OnInit, OnDestroy {
  selectedTabIndex = 0;
  MenuDisabled;
  @ViewChild(RestuarantsDetailsComponent, null)
  public RestuarantDetails: RestuarantsDetailsComponent;

  ShowRefuseDialog: boolean = false;
  ShowApproveDialog: boolean = false;
  selectedRefuseReasonId;
  id;
  userGroup = UserGroup;
  departmentEnum = DepartmentsEnum;
  generalStatus = GeneralStatus;

  constructor(
    private service: RestaurantsService,
    private socket: Socket,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    this.id = this.router.snapshot.paramMap.get("id");
    this.service.getMenuDisabled().subscribe((res) => {
      console.log(res, "res");
      this.MenuDisabled = res;
    });

    // load active tab
    if (localStorage.getItem("RESTURANT_TAB_INDEX")) {
      this.selectedTabIndex = JSON.parse(
        localStorage.getItem("RESTURANT_TAB_INDEX")
      );
    } else {
      this.save_resturant_tab_index();
    }
  }

  ngOnDestroy(): void {
    // localStorage.removeItem("RESTURANT_TAB_INDEX");
  }

  save_resturant_tab_index() {
    console.log(this.selectedTabIndex);

    localStorage.setItem(
      "RESTURANT_TAB_INDEX",
      JSON.stringify(this.selectedTabIndex)
    );
  }
}
