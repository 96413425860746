import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AreasService } from "src/core/services/areas/areas.service";
import { BranchesService } from "src/core/services/branches/branches.service";
import { OrdersAdminService } from "src/core/services/orders-admin/orders-admin.service";
import { OrdersService } from "src/core/services/orders/orders.service";
import { PaymentsService } from "src/core/services/payments/payments.service";
import { RestaurantsService } from "src/core/services/restaurants/restaurants.service";
import * as moment from "moment";
import { DropdownService } from "src/core/services/dropdown/dropdown.service";

@Component({
  selector: "app-order-admin-filter",
  templateUrl: "./order-admin-filter.component.html",
  styleUrls: ["./order-admin-filter.component.scss"],
})
export class OrderAdminFilterComponent implements OnInit {
  @Output() filterEmit: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  branches: any[] = [];
  RestaurantId: AnalyserNode;
  restaurants: any[] = [];
  areas: any[] = [];
  payments: any[] = [];
  status: any[] = [];
  hasFilter: boolean = true;

  constructor(
    private fb: FormBuilder,
    private branche: BranchesService,
    private payment: PaymentsService,
    private statue: OrdersService,
    private route: ActivatedRoute,
    private dropdownService: DropdownService
  ) {}

  ngOnInit() {
    this.createForm();

    this.allData();

    const querryParams = this.route.snapshot.queryParams;
    if (Object.keys(querryParams).length) {
      this.hasFilter = false;
      this.form.reset({
        restaurant: +querryParams.restaurant || null,
        branch: +querryParams.branch || null,
        payment: +querryParams.payment || null,
        date_from: querryParams.startDate,
        date_to: querryParams.endDate,
      });
      if (querryParams.restaurant) {
        this.getBranches(querryParams.restaurant);
      }
    }

    this.RestaurantId = JSON.parse(localStorage.getItem("RestaurantId"));

    if (this.RestaurantId) {
      this.getBranches(this.RestaurantId);
    }
  }

  createForm() {
    this.form = this.fb.group({
      id: [null],
      restaurant: [null],
      branch: [null],
      area: [null],
      payment: [null],
      status: [null],
      date_from: [new Date(new Date().getFullYear(), new Date().getMonth(), 1)],
      date_to: [new Date()],
    });
  }

  async allData() {
    const data: any = await Promise.all([
      this.dropdownService.FilterRestaurant({}),
      this.dropdownService.getAreas(),
      this.payment.get(),
      this.statue.status(),
    ]);

    this.restaurants = data[0];
    this.areas = data[1];
    this.payments = data[2];
    this.status = data[3];
  }

  reset() {
    this.form.reset();

    this.filterEmit.emit("reset");
  }

  filter() {
    const values = this.form.value;
    if (values.date_from == null || values.date_to == null) {
      const now = new Date();
      values.date_from = new Date(now.getFullYear(), now.getMonth(), 1);
      values.date_to = now;
      this.form.reset({
        date_from: values.date_from,
        date_to: values.date_to,
      });
    }
    if (values.date_from) {
      values.date_from = moment(values.date_from).format("YYYY-MM-DD");
    }
    if (values.date_to) {
      values.date_to = moment(values.date_to).format("YYYY-MM-DD");
    }

    this.filterEmit.emit(values);
  }

  async getBranches(id) {
    console.log("res id : ", id);

    this.branches = await this.branche.getRestaurant(id).toPromise();

    console.log("branches : ", this.branches);
  }
}
