import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-report-filter',
  templateUrl: './user-report-filter.component.html',
  styleUrls: ['./user-report-filter.component.scss']
})
export class UserReportFilterComponent implements OnInit {
  form:FormGroup
  @Output() formEmiiter = new EventEmitter<any>();
  constructor(private fb:FormBuilder) { }

  ngOnInit() {
    this.initForm()
  }

  initForm(){
    this.form = this.fb.group({
      startto:[null],
      startfrom:[null],
    })
  }

  reset(){
    this.form.reset()
    this.formEmiiter.emit({});
  }

  filter(){
    if(this.form.valid){
      this.formEmiiter.emit(this.form.value);
    }
  }
}
