import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { RestaurantAttachmentService } from "src/core/services/RestaurantAttachment/RestaurantAttachment.service";
import { saveAs } from "file-saver";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { GenericService } from "src/core/services/generic/generic.service";

@Component({
  selector: "app-attachment",
  templateUrl: "./attachment.component.html",
  styleUrls: ["./attachment.component.scss"],
})
export class AttachmentComponent implements OnInit, OnChanges {
  @Input() title: any = "";
  @Input() images: any[] = [];
  @Input() id;
  @Input() type;
  @Output() img: EventEmitter<any> = new EventEmitter();
  @Input() keyForInput: number;
  image: any;
  cover: any;
  coverImg: any;
  imgs: any[] = [];
  canUpload: boolean;
  canDownload: boolean;
  progress = 0;

  default = "./../../../assets/images/default.png";
  URL = environment.photoPath;

  constructor(
    private sanitizer: DomSanitizer,
    private general: GenericService,
    private service: RestaurantAttachmentService
  ) {}

  ngOnInit() {
    console.log("images ", this.images);
  }

  getImage(files: FileList) {
    this.image = files;
    for (let i = 0; i < files.length; i++) {
      this.onImageUpload(files[i]);
    }
  }

  upload() {
    const formData = new FormData();
    formData.append("restaurant_id", this.id);
    formData.append("type ", this.type);

    for (let file of this.image) {
      formData.append("photo", file);
    }

    this.service.add(formData).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          var eventTotal = event.total ? event.total : 0;
          this.progress = Math.round((event.loaded / eventTotal) * 100);
          break;
        case HttpEventType.Response:
          setTimeout(() => {
            this.progress = 0;
            this.general.showNotification(
              "success",
              "اضافة",
              "تمت الاضافة بنجاح"
            );
            this.canUpload = false;
          }, 1000);
      }
    });
  }

  onImageUpload(file: any) {
    const uploadedFile = file;
    const reader = new FileReader();

    reader.onloadend = (e: any) => {
      event;
      const imgBase64Path = e.target.result;
      const cardImageBase64 = this.transform(e.target.result);

      this.imgs.push({ src: cardImageBase64 });
      this.cover = this.imgs[0];
      this.img.emit(this.image);
      this.canUpload = true;
    };

    reader.readAsDataURL(uploadedFile);
  }

  transform(base64) {
    return this.sanitizer.bypassSecurityTrustUrl(base64);
  }

  download() {
    for (let i = 0; i < this.imgs.length; i++) {
      console.log(this.imgs[i]);
      saveAs(this.imgs[i], this.title);
    }
  }

  deleteImg(id) {
    this.service.delete(id).then(
      (res) => {
        this.imgs = this.imgs.filter((val) => val.id != id);
        this.general.showNotification("success", "حذف", "تم الحذف بنجاح");
        this.cover = this.imgs[0];
      },
      (error) => {}
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const prop in changes) {
      if (changes.hasOwnProperty(prop)) {
        switch (prop) {
          case "images":
            this.images = changes.images.currentValue;
            if (this.images.length > 0)
              this.imgs = this.images.map((val) => {
                return { id: val.id, src: `${this.URL}/${val.photo}` };
              });
            if (this.imgs.length) this.cover = this.imgs[0];
            if (this.imgs.length) this.canDownload = true;
            break;
        }
      }
    }
  }
}
