import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RenderPipe } from "src/core/pipes/render.pipe";
import { RoundPipe } from "src/core/pipes/round.pipe";

// all used pipes

@NgModule({
  declarations: [RenderPipe, RoundPipe],
  imports: [CommonModule],
  exports: [RenderPipe, RoundPipe],
})
export class PipesModule {}
