import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { GenericService } from "src/core/services/generic/generic.service";
import { NotificationsService } from "src/core/services/notifications/notifications.service";

@Component({
  selector: "app-notifications-index",
  templateUrl: "./notifications-index.component.html",
  styleUrls: ["./notifications-index.component.css"],
})
export class NotificationsIndexComponent implements OnInit {
  data: any[] = [];
  heads = ["#", "العنوان", "الرسالة", "الصورة", "Actions"];
  pages: any = {};
  loading: boolean;
  URL = environment.photoPath;
  page = 1;
  canLoadMore: boolean = true;

  constructor(
    private generic: GenericService,
    private service: NotificationsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.get();
  }

  async get() {
    const data: any = await this.service
      .get()
      .then((res: any) => {
        this.data = res;
      })
      .catch((err) => (this.canLoadMore = false));
    //this.data = data.brands;
    //this.pages = data.pagination
  }

  edit(item) {
    const obj = JSON.stringify(item);
    localStorage.setItem("item", obj);
    this.router.navigate([`notifications/edit`]);
  }

  async delete(id, index) {
    this.loading = true;
    const data = this.service.delete(id).then(
      () => {
        this.data.splice(index, 1);
        this.loading = false;
        this.generic.showNotification("success", "حذف", "تمت العملية بنجاح");
      },
      () => {
        this.loading = false;
        this.generic.showNotification(
          "success",
          "حذف",
          "حدث خطأ بالرجاء المحاولة في وقت الحق"
        );
      }
    );
  }

  loadMore() {
    // DO LATER THE PAGINATION
  }
}
