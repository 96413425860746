import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MenuItemsTopics } from 'src/core/models/MenuModel';
import { options } from 'src/app/options';
import { environment } from 'src/environments/environment';
import { MenuCategories, MenuItems, TownsCreate } from '../cities/citiesModel';

@Injectable({
  providedIn: 'root'
})
export class MinMenuService {

  constructor(private http: HttpClient) { }
  getMenuCategories(restId:string) {

    return this.http.get<Array<MenuCategories>>(`${environment.endpoint}/Menu/getMenuCategories/${restId}`).toPromise();

  }

  getItemsByCategories(catId:number) {
    return this.http.get<Array<MenuItems>>(`${environment.endpoint}/Menu/getItemsByCategories/${catId}`).toPromise();
  }

  deleteItemsByCategories(catId:number) {
    return this.http.get<number>(`${environment.endpoint}/Menu/deleteItemsByCategories/${catId}`).toPromise();
  }

  Delete(catId:number) {

    return this.http.get<Array<MenuItems>>(`${environment.endpoint}/MenuCategories/delete/${catId}`).toPromise();

  }

  DeleteMenuItem(catId:number) {
    return this.http.get<any>(`${environment.endpoint}/Menu/delete/${catId}`).toPromise();
  }

  editMenuItem(itemId: number, data) {
    return this.http.post<any>(`${environment.endpoint}/Menu/edit/${itemId}`,data).toPromise();
  }

  addNewItemInMenu(data) {
    return this.http.post<any>(`${environment.endpoint}/Menu/addMenuItems`,data,options).toPromise();
  }

  addNewItemInMenuFromExcel(data) {

    return this.http.post<any>(`${environment.endpoint}/MenuItem/add`,data,options).toPromise();

  }
  addNewOptionAndTopicInMenu(data) {

    return this.http.post<any>(`${environment.endpoint}/Menu/addMenuTopics`,data).toPromise();

  }

  addCategoryMenu(data) {

    return this.http.post<any>(`${environment.endpoint}/Menu/addMenuCategories`,data).toPromise();

  }
  editCategoryMenuVendor(data) {
    return this.http.post<any>(`${environment.endpoint}/EditMenuRequests/add`,data).toPromise();
  }


  deleteOption(id: number) {
    return this.http.get(`${environment.endpoint}/MenuOptions/delete/${id}`).toPromise()
  }


  deleteTopic(id: number) {
    return this.http.get(`${environment.endpoint}/MenuTopics/delete/${id}`).toPromise()
  }
}
