import { Router } from "@angular/router";
import { GenericService } from "src/core/services/generic/generic.service";
import { Component, OnInit } from "@angular/core";
import { UsersService } from "src/core/services/users/users.service";
import { NzModalRef, NzModalService } from "ng-zorro-antd";
import { UserSharedDataService } from "src/core/services/users/user-shared-data.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-users-index",
  templateUrl: "./users-index.component.html",
  styleUrls: ["./users-index.component.css"],
})
export class UsersIndexComponent implements OnInit {
  users: any[] = [];
  page = 1;
  limit = 5;
  filterData;
  counts: any = {
    weekclient: {},
    monthclient: {},
  };
  filterSub: Subscription;
  heads = [
    "الاسم",
    "اسم المستخدم",
    "رقم الهاتف",
    "البريد الالكتروني",
    "الوظيفة",
    "Actions",
  ];

  loading: boolean;
  constructor(
    public service: UsersService,
    public NZmodalService: NzModalService,
    public modal: NzModalRef,
    public generic: GenericService,
    public router: Router,
    private userShared: UserSharedDataService
  ) {}

  ngOnInit() {
    this.getUsers();
    this.getReport();

    this.filterSub = this.userShared.user_filterData.subscribe((form) => {
      if (form == "reset") this.reset();
      else this.filter(form);
    });
  }

  async getUsers() {
    const users = await this.service.getUsersPagination({
      page: this.page,
      limit: this.limit,
    });
    this.users = users as any;
  }

  async filter(form) {
    let props: any[] = [
      Boolean(form.id) && { id: parseInt(form.id) },
      Boolean(form.mobile) && { mobile: form.mobile },
      Boolean(form.zone) && { "billing_address.area_id": form.zone },
    ];

    props = props.filter((val) => Boolean(val));
    let obj = {};
    props.forEach((val) => (obj = { ...obj, ...val }));
    this.filterData = obj;
    const data: any = await this.service.getUsers(obj, {
      page: this.page,
      limit: this.limit,
    });
    this.users = data as any;
  }

  reset() {
    // this.users = this.temp;
  }

  async delteUser(id, index) {
    this.loading = true;
    const data = this.service.deleteUser(id);
    this.users.splice(index, 1);
    this.loading = false;
    // this.modal.close()
    this.generic.showNotification("success", "حذف", "تمت العملية بنجاح");
  }

  editUser(user) {
    const obj = JSON.stringify(user);
    localStorage.setItem("item", obj);
    this.router.navigate([`users/edit/${user.id}`]);
  }

  async getReport() {
    const data = await this.service.getReport({});
    this.counts.weekclient = { ...data["weekclient"][0] };
    this.counts.monthclient = { ...data["Monthclient"][0] };
  }

  onPageChange(page) {
    this.page = page;
    if (Object.keys(this.filterData).length > 0) this.filter(this.filterData);
    else this.getUsers();
  }

  onSizeChange(limit) {
    this.limit = limit;
    if (Object.keys(this.filterData).length > 0) this.filter(this.filterData);
    else this.getUsers();
  }
}
