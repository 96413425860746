import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BranchesService } from "src/core/services/branches/branches.service";
import { GenericService } from "src/core/services/generic/generic.service";
import { branches, Users } from "src/core/services/restaurants/RestaurantModel";
import * as lang from "src/settings/lang";
import { Location } from "@angular/common";
import { NzModalRef } from "ng-zorro-antd";
import { validations } from "src/core/helper/validations";
import { SharedService } from "src/core/shared/shared.service";

@Component({
  selector: "app-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.css"],
})
export class AddComponent implements OnInit {
  resId;
  BranchObj = new branches();
  geocoder = new google.maps.Geocoder();
  User = new Users();
  coordinate;
  lat = 0;
  lng = 0;
  zoom = 15;
  mapindex = 0;
  isedit = false;
  selectedArea = 0;
  isAdd: boolean;
  @ViewChild("map3", { static: true }) map3;
  ArrayAreas: any;
  isLocationVisible: boolean;
  validCoordinate: boolean;

  constructor(
    private service: BranchesService,
    private modal: NzModalRef,
    private location: Location,
    public navigate: Router,
    private activatedRoute: ActivatedRoute,
    private generic: GenericService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.resId = this.activatedRoute.snapshot.paramMap.get("id");

    if (window.history.state.id) {
      this.BranchObj = window.history.state;
      this.isedit = true;
      delete this.BranchObj.navigationId;
    }
    setTimeout(() => {
      console.log(this.BranchObj);
    }, 500);
  }

  markerDragEnd(m: any, i) {
    let lat = m.coords.lat;
    let lng = m.coords.lng;
    this.geocoder.geocode(
      {
        location: {
          lat: lat,
          lng: lng,
        },
      },
      (result, status) => {
        localStorage.setItem(
          "lat",
          result[0].geometry.location.lat().toString()
        );
        localStorage.setItem(
          "lng",
          result[0].geometry.location.lng().toString()
        );
        this.coordinate =
          result[0].geometry.location.lat() +
          "," +
          result[0].geometry.location.lng();

        this.ArrayAreas[this.mapindex].latitude =
          result[0].geometry.location.lat();
        this.ArrayAreas[this.mapindex].longitude =
          result[0].geometry.location.lng();
      }
    );
  }

  changeAddress(event, i) {
    let address = event.target.value;

    this.geocoder.geocode({ address: address }, (result, status) => {
      this.lat = result[0].geometry.location.lat();
      this.lng = result[0].geometry.location.lng();
      this.BranchObj.latitude = this.lat;
      this.BranchObj.longitude = this.lng;
      this.coordinate =
        this.BranchObj.latitude + "," + this.BranchObj.longitude;
      localStorage.setItem("lat", this.lat.toString());
      localStorage.setItem("lng", this.lng.toString());
    });
  }
  editLocation(e, i) {
    // e.stopPropagation();
    this.mapindex = i;

    this.isLocationVisible = true;
  }
  updateLocation() {
    this.isLocationVisible = false;
  }

  async add() {
    if (!new validations(document).vlidate()) return;

    // username and pass
    // this.User.password = "sata@123";
    if (
      this.BranchObj.phone_two &&
      this.BranchObj.phone_two != this.BranchObj.phone
    ) {
      // تاكد من ان ارقام التليفونات مختلفة
      if (this.isedit == true) {
        delete this.BranchObj.expand;
        this.BranchObj.restaurantsId = parseInt(this.resId);
        this.BranchObj.latitude = this.coordinate.split(",")[0].trim();
        this.BranchObj.longitude = this.coordinate.split(",")[1].trim();
        const data = await this.service.edit(this.BranchObj);
        this.generic.showNotification(
          "success",
          lang.ar.addNewTitle,
          lang.ar.addNewMsg
        );
        this.modal.close();
      } else {
        console.log(this.coordinate);
        this.User.username = this.User.username + "@sata.com";
        delete this.BranchObj.expand;
        this.BranchObj.restaurantsId = parseInt(this.resId);
        this.BranchObj.latitude = parseFloat(
          this.coordinate.split(",")[0].trim()
        );
        this.BranchObj.longitude = parseFloat(
          this.coordinate.split(",")[1].trim()
        );

        if (new validations(document).vlidate()) {
          this.BranchObj.user = this.User;
          this.BranchObj.code = this.sharedService.generateCode(10);

          const { expand, ...rest } = this.BranchObj;

          const data = await this.service.add(rest);
          this.generic.showNotification(
            "success",
            lang.ar.addNewTitle,
            lang.ar.addNewMsg
          );
          this.modal.close();
        }
      }
    }
  }
}
