import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { options } from 'src/app/options';
import { environment } from 'src/environments/environment';
import { AreasCreate, BranchesZones, TownsCreate } from '../cities/citiesModel';

@Injectable({
  providedIn: 'root'
})
export class AreasService {


  constructor(private http: HttpClient) { }
  getAreaList() {
    return this.http.get<Array<TownsCreate>>(`${environment.endpoint}/Cities/Areas/index`).toPromise();
  }
  getAreaListForZones() {
    return this.http.get<BranchesZones[]>(`${environment.endpoint}/Cities/getCitiesForApp`).toPromise();
  }
  getTownsList() {
    return this.http.get<Array<TownsCreate>>(`${environment.endpoint}/Cities/getTowns`).toPromise();
  }

  get() {
    return this.http.get(`${environment.endpoint}/Areas/index`).toPromise();
  }

  add(data: any) {
    return this.http.post(`${environment.endpoint}/Areas/add`, data,options).toPromise();
  }

  edit(data: any, id) {
    return this.http.post(`${environment.endpoint}/areas/edit/${id}`, data,options).toPromise();
  }

  GetCities() {
    return this.http.get<Array<AreasCreate>>(`${environment.endpoint}/Cities/getCities`).toPromise();
  }

  delete(id) {
    return this.http.get(`${environment.endpoint}/areas/delete/${id}`).toPromise();
  }

  GetAdsSpacesName() {
    return this.http.get<Array<AreasCreate>>(`${environment.endpoint}/AdsSpacesName/index`)
  }

  getGoogleId(location:string) {
    return this.http.get<any>(`https://maps.googleapis.com/maps/api/place/textsearch/json?query=${location}&key=AIzaSyBxgJFTad1Ir0m_pn7dfzm3qVGnK7IyoFQ`).toPromise()
  }

}
