import { Component, OnInit } from "@angular/core";
import { GenericService } from "src/core/services/generic/generic.service";
import { Router } from "@angular/router";
import { AddIndexEditDeleteService } from "src/core/services/add-index-edit-delete/add-index-edit-delete.service";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"],
})
export class IndexComponent implements OnInit {
  dataArray: HomeModel[];
  loading: boolean;
  data: any;
  HomeModel: any;
  location: any;

  constructor(
    private service: AddIndexEditDeleteService,
    private generic: GenericService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.getData();
  }

  async getData() {
    let res = await this.service.GetAddIndexEditDelete();
    this.data = res;
  }

  add(data) {
    const obj = JSON.stringify(data);
    localStorage.setItem("item", obj);
    this.router.navigateByUrl("/AddIndexEditDelete/add");
  }

  editAd(data) {
    const obj = JSON.stringify(data);
    localStorage.setItem("item", obj);
    this.router.navigateByUrl("/AddIndexEditDelete/edit");
  }

  async deleteAd(id, index) {
    this.loading = true;
    const data = this.service
      .deleteOffer(id)
      .toPromise()
      .then(
        () => {
          this.data.splice(index, 1);
          this.loading = false;
          this.generic.showNotification("success", "حذف", "تمت العملية بنجاح");
        },
        () => {
          this.loading = false;
          this.generic.showNotification(
            "success",
            "حذف",
            "فشلت العملية بالرجاء المحاولة في وقت لاحق"
          );
        }
      );
  }

  onKeyUp(newTitle: string) {
    this.data.title = newTitle;
  }
}

export interface HomeModel {
  id?: string;
  title?: string;
  positions?: string;
  title_en?: string;
  created?: number;
  modified?: number;
}
